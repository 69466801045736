.paginator {
  display: flex;
  user-select: none;
  &__prev,
  &__next,
  &__page {
    width: 32px;
    height: 32px;
    margin: 2px;
    border: 1px solid $secondary-0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__prev,
  &__next {
    color: $secondary;
    &.disable {
      opacity: 70%;
      color: $secondary-0;
      cursor: default;
    }
  }
  &__page {
    &.active {
      background: $primary;
      color: white;
      border-color: $primary;
    }
  }
}
