.vivela-tracking-stepper {
  VivelaTrackingStep {
    margin-bottom: 20;
  }
}

.vivela-tracking-step {
  &__content {
    background-color: $surface;
    padding: 0 20;
    border-top-right-radius: 15;
    border-bottom-right-radius: 15;
  }
  &__title {
    font-size: 14;
    color: $on-background-high-emphasis;
    @extend .text-medium;
  }
  &__description {
    font-size: 12;
    color: $on-background-high-emphasis;
    text-wrap: true;
    @extend .text-regular;
  }
  &__circle {
    background-color: $primary-light;
    border-radius: 10;
    height: 20;
    width: 20;
    VivelaIcon {
      color: $on-primary-high-emphasis;
    }
  }
  &__line {
    background-color: $primary-light;
    border-bottom-right-radius: 2;
    border-bottom-left-radius: 2;
    width: 4;
  }
  &__ellipsis {
    width: 2;
    Label {
      background-color: $primary-light;
      border-bottom-right-radius: 1;
      border-bottom-left-radius: 1;
      width: 2;
      height: 2;
      margin-top: 8;
    }
  }
}

.vivela-tracking-step--error {
  & .vivela-tracking-step__content {
    background-color: $error-light;
    padding: 20 20;
  }
  & .vivela-tracking-step__circle {
    background-color: $error;
    VivelaIcon {
      color: $on-error;
    }
  }
  & .vivela-tracking-step__line {
    background-color: $error;
  }
}

.vivela-tracking-step--success {
  & .vivela-tracking-step__content {
    padding: 0 20;
  }
  & .vivela-tracking-step__circle {
    background-color: $success;
    VivelaIcon {
      color: $on-success;
    }
  }
  & .vivela-tracking-step__line {
    background-color: $success;
  }
  & .vivela-tracking-step__ellipsis {
    Label {
      background-color: $success;
    }
  }
}

.vivela-tracking-step--current {
  & .vivela-tracking-step__content {
    background-color: $secondary-light;
    padding: 20 20;
  }
  & .vivela-tracking-step__title {
    color: $secondary;
  }
  & .vivela-tracking-step__circle {
    background-color: $secondary;
    VivelaIcon {
      color: $on-secondary-high-emphasis;
    }
  }
  & .vivela-tracking-step__line {
    background-color: $secondary;
  }
}
