@font-face {
  font-family: VivelaIcons;
  src: url('./../assets/fonts/VivelaIcons.ttf');
  font-style: normal;
  font-weight: 400;
}

.icon {
  font-style: normal;
  font-family: VivelaIcons;
  display: block;
}
