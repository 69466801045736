$space-stops: (
  '0': size(0),
  '5': size(5),
  '8': size(8),
  '10': size(10),
  '12': size(12),
  '15': size(15),
  '16': size(16),
  '18': size(18),
  '20': size(20),
  '25': size(25),
  '30': size(30),
  '32': size(32),
  '35': size(35),
  '40': size(40),
  '45': size(45),
  '50': size(50),
  '55': size(55),
  '60': size(60),
  '65': size(65),
  '72': size(72),
  '95': size(95),
  '100': size(100),
  'auto': auto,
);

@each $key, $val in $space-stops {
  .p-#{$key} {
    padding: #{$val};
  }
  .pt-#{$key} {
    padding-top: #{$val};
  }
  .pr-#{$key} {
    padding-right: #{$val};
  }
  .pb-#{$key} {
    padding-bottom: #{$val};
  }
  .pl-#{$key} {
    padding-left: #{$val};
  }
  .px-#{$key} {
    padding-right: #{$val};
    padding-left: #{$val};
  }
  .py-#{$key} {
    padding-top: #{$val};
    padding-bottom: #{$val};
  }

  .m-#{$key} {
    margin: #{$val};
  }
  .mt-#{$key} {
    margin-top: #{$val};
  }
  .mr-#{$key} {
    margin-right: #{$val};
  }
  .mb-#{$key} {
    margin-bottom: #{$val};
  }
  .ml-#{$key} {
    margin-left: #{$val};
  }
  .mx-#{$key} {
    margin-right: #{$val};
    margin-left: #{$val};
  }
  .my-#{$key} {
    margin-top: #{$val};
    margin-bottom: #{$val};
  }
}
