.prequalification-import {
  .summary {
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-top: 55px;
    padding: 10px;
    height: 427px;
    overflow-y: scroll;
  }
}
