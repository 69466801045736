body {
  min-width: 360px;
  @extend .text-secondary;
  @extend .bg-white;
}

a {
  @extend .body-2;
  @extend .text-regular;
  @extend .text-primary-300;
}

a.return-normal {
  display: none;
  @extend .text-medium;
  @extend .text-primary-400;
}

button.return-mobile {
  display: block;
}

.mat-slider-min-value {
  .mat-slider-thumb-label {
    background-color: $primary !important;
  }
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.layout {
  display: flex;
  flex-direction: column;
}

.main-content {
  @extend .py-32;
  @extend .px-16;
  @extend .w-100;
}

.row {
  p {
    @extend .text-sm;
    @extend .text-regular;
    @extend .mb-16;
  }
}

// header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  &__logo {
    img {
      max-height: 70px;
    }
  }

  &__profile {
    overflow: hidden;
    border-radius: 100%;

    img {
      width: 50px;
      height: 50px;
    }
  }
}

.menu {
  display: flex;

  &__item {
    text-decoration: none;
    padding: 10px 20px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      color: $primary;
    }
  }
}

// page head
.page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;
  margin: 30px 0;
  color: $secondary;
  font-weight: 700;
}

.report-card {
  margin-bottom: 30px;
}

.profile-card {
  //margin-left: 0;
  //margin-right: 0;
  //padding: 35px 0;

  .h3 {
    margin-bottom: 10px;
  }

  &__img {
    text-align: center;

    img {
      max-width: 130px;
    }
  }
}

// login
.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @extend .bg-white;

  &__intro {
    flex: none;
    @extend .bg-primary-300;
    @extend .rounded-br-90;

    &__brand {
      display: flex;
      justify-content: space-between;
      @extend .py-18;
      @extend .px-25;

      img {
        @extend .w-95;
        @extend .h-auto;
        @extend .cursor-pointer;
      }
    }

    &__info {
      @extend .mt-32;
      @extend .mx-16;
      @extend .mb-45;
      @extend .text-primary-0;

      .title {
        @extend .mb-8;
        @extend .text-5xl;
        @extend .tracking-wide;
        @extend .text-light;
      }

      p {
        @extend .text-sm;
        @extend .tracking-wide;
        @extend .text-regular;
        @extend .text-white;
      }
    }

    &__img {
      display: none;
      @extend .my-20;
      @extend .mx-auto;
      @extend .text-center;
    }
  }

  &__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    @extend .pt-32;
    @extend .px-16;
    @extend .pb-72;

    .title {
      @extend .mb-32;
      @extend .text-3xl;
      @extend .text-bold;
      @extend .text-primary-300;
    }

    .subtitle {
      @extend .mb-8;
      @extend .text-sm;
      @extend .text-regular;
      @extend .text-bold;
    }

    .google-btn {
      @extend .mb-32;
      width: 100%;
    }
  }
}

.recover {
  .login__intro {
    @extend .rounded-br-0;
  }

  .login__intro__content {
    display: none;
  }
}

// sidebar
.sidebar {
  width: 100%;
  min-width: 360px;
  height: auto;
  min-height: auto;
  @extend .text-white;
  @extend .bg-primary;

  &__brand {
    display: flex;
    justify-content: space-between;
    @extend .py-18;
    @extend .px-25;

    img {
      @extend .w-95;
      @extend .h-auto;
      @extend .cursor-pointer;
    }

    button {
      display: block;
    }
  }

  .nav-list {
    z-index: 10;
    @extend .position-absolute;
    @extend .w-100;
    @extend .bg-primary;
  }

  &__menu {
    @extend .m-0;
    min-height: 400px;

    &__section {
      @extend .mb-0;
      border-bottom: 1px solid #339599;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      user-select: none;
      @extend .py-25;
      @extend .px-45;
      @extend .radius-0;
      @extend .cursor-pointer;

      &__text {
        @extend .ml-10;
        @extend .text-sm;
        @extend .text-regular;
      }

      &:hover,
      &.active {
        @extend .text-primary-400;
        @extend .bg-primary-0;

        .sidebar__menu__item__text {
          @extend .text-bold;
        }
      }
    }
  }

  &__submenu {
    margin-left: 58px;
    border-left: 1px solid $primary-0;

    &__item {
      user-select: none;
      @extend .py-25;
      @extend .px-45;
      @extend .text-xs;
      @extend .text-regular;
      @extend .radius-0;
      @extend .cursor-pointer;

      &:hover,
      &.active {
        @extend .text-bold;
        @extend .bg-primary-400;
      }
    }
  }

  &__user {
    @extend .py-25;
    @extend .px-45;
    display: flex;
    border-top: 1px solid #339599;
    border-bottom: 1px solid #339599;

    &__avatar {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .w-40;
      @extend .h-40;
      @extend .bg-white;
      @extend .rounded-circle;

      &__text {
        @extend .text-base;
        @extend .text-bold;
        @extend .text-primary;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @extend .ml-10;
      @extend .text-sm;
      @extend .text-regular;
    }
  }

  &__logout {
    button.mat-stroked-button {
      @extend .w-100;
      @extend .py-25;
      @extend .px-auto;
    }
  }
}

.welcome-content {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .h-100;
  min-height: calc(100vh - 180px);
}

.prequalifications-search {
  display: inline-flex;
  width: 100%;
  @extend .mb-32;

  .mat-form-field {
    @extend .w-auto;
    max-width: 195px;

    .mat-form-field-wrapper {
      @extend .m-0;
      @extend .p-0;
    }
  }

  button.mat-flat-button {
    @extend .ml-8;
    @extend .mb-0;
  }
}

.prequalifications-button-top {
  .btn-icon {
    display: none;
  }

  .mat-stroked-button {
    display: block;
    @extend .mb-16;
    @extend .w-100;
  }
}

.prequalifications-button-bottom {
  .btn-icon {
    display: none;
  }

  .mat-stroked-button {
    display: block;
    @extend .mt-16;
    @extend .w-100;
  }
}

// tables
.card {
  .table-wrapper {
    @extend .mt-10;
    display: grid;
    box-shadow: none;
    border: 1px solid #dadada;

    table {
      width: 100%;
      min-width: 600px;
    }
  }
}

.table-wrapper {
  @extend .position-relative;
  @extend .radius-8;
  @extend .bg-white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);

  .content,
  .table-content {
    overflow-x: scroll;

    .mat-row {
      @extend .cursor-pointer;
    }

    .mat-row:hover {
      background: whitesmoke;
    }
  }

  table,
  table.mat-table {
    @extend .bg-inherit;
    width: 100%;
    min-width: 1300px;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid $secondary-100;

      &:last-child {
        border-bottom: 0px;
      }

      &.mat-no-data-row {
        td {
          @extend .text-left;
        }
      }
    }

    th,
    td {
      @extend .py-20;
      @extend .px-16;
      @extend .text-sm;
      @extend .text-left;
    }

    th {
      @extend .text-bold;
      @extend .text-secondary;
    }

    td {
      @extend .text-regular;
      @extend .text-secondary-300;
    }
  }

  mat-paginator {
    display: flex;
    justify-content: center;
    padding-right: 50px;
  }

  .button-plus {
    position: absolute;
    right: -12px;
    bottom: -12px;
  }
}

.mat-table,
.mat-paginator {
  @extend .bg-transparent;
}

.container {
  padding: 0 !important;
  max-width: 100%;
  @extend .pt-12;
  @extend .bg-transparent;

  .subtitle {
    @extend .mb-25;
    @extend .text-base;
    @extend .text-bold;
    @extend .text-primary;
  }

  .mat-flat-button {
    width: 100%;
  }
}

.rule {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $secondary-0;
  @extend .py-25;

  &:last-child {
    border-bottom: 0;
    @extend .pb-0;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    @extend .w-100;
    @extend .text-xs;

    &__code {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      font-size: 0.5rem;
      line-height: 0.75rem;
      @extend .mr-5;
      @extend .text-white;
      @extend .text-extrabold;
      @extend .rounded-circle;
    }

    &__content {
      display: flex;
      flex-direction: column;
      @extend .w-auto;

      &__description {
        display: flex;
        align-items: center;
        flex: 1;
        @extend .text-secondary-300;
      }

      &__periods {
        display: flex;
        align-items: center;
        @extend .text-bold;
        @extend .text-right;
        @extend .text-uppercase;
        @extend .text-secondary-100;

        vv-icon {
          @extend .ml-5;
        }
      }
    }
  }

  &__detail {
    @extend .ml-0;
    @extend .w-100;

    ul {
      @extend .ml-20;

      li {
        @extend .mt-10;
        @extend .text-sm;
      }
    }
  }
}

.simulation-card {
  border: 1px solid #dadada;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  &-primary {
    border: 2px solid $primary;
  }

  &__years {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 15px;
  }

  &__amount {
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
  }

  .simulator__list {
    width: 100%;
    margin-top: 15px;

    &__item {
      display: flex;
      padding: 20px 0;
      align-items: center;

      &__icon {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        @extend .text-primary;
      }
    }
  }
}

.mat-form-field-appearance-outline.mat-form-field-disabled {
  .mat-form-field-outline {
    background: $secondary-0;
    color: $secondary-200;
  }

  .mat-input-element:disabled,
  .mat-select-value-text {
    color: $secondary-200;
  }
}

.mat-form-field-readonly {
  .mat-form-field-outline {
    background: $secondary-0;
    color: $secondary;
    border-radius: 7px;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-color: $secondary-100 !important;
  }

  .mat-form-field-outline-gap {
    border-top: 0 !important;
  }

  .mat-form-field-label {
    color: $secondary;
  }
}

.user-form,
.application-form {
  .credit-info {
    border: 1px solid $secondary-200;
    box-sizing: border-box;
    border-radius: 5px;

    &__title {
      font-weight: 700;
      font-size: 40px;
      color: $primary;
    }

    &__subtitle {
      font-size: 20px;
      color: $primary-500;
    }

    &__monthly-fee {
      @extend .mb-15;
      @extend .text-8xl;
      @extend .text-light;
      @extend .text-primary;
    }

    ul {
      list-style: none;

      li {
        color: $secondary-200;

        .text-bold {
          color: $primary-500;
        }
      }

      i.icon {
        width: 31px;
        height: 31px;
        color: $primary-200;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    a {
      background: #f5f5f5;
      border: 1px solid rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      color: $primary-500;
      outline: none;
      text-decoration: none;
    }

    p {
      margin-bottom: 0;
    }
  }

  .application-title {
    position: relative;
  }

  .print-float {
    position: initial;
    top: 0;
    right: 0;
    z-index: 100;
  }
}

//skeleton
@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.9),
      transparent
    );
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    animation: loading 0.6s infinite;
  }

  p,
  h1,
  h2,
  [class*='heading-'],
  .list__item,
  table {
    thead {
      position: relative;
    }

    span {
      background: #eee;
      color: #eee;
      pointer-events: none;
      user-select: none;
    }
  }

  [class*='col-'] {
    position: inherit;
  }

  .form-field {
    height: 59px;
    margin-bottom: 25px;
    background: #eee;
    color: #eee;
    border-radius: 5px;
  }

  .form-button {
    background: #eee;
    color: #eee;
    height: 36px;
  }

  .form-chip {
    background: #eee;
    color: #eee;
    height: 54px;
    width: 120px;
    border-radius: 30px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .list__item__decorator,
  .rule__code,
  .badge__circle,
  .badge__text,
  .mat-slide-toggle-bar,
  .mat-slide-toggle-thumb {
    color: #eee !important;
    background-color: #eee !important;
    pointer-events: none;
    user-select: none;
  }
}

.mat-icon-button.btn-icon-add {
  border-radius: 5px;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary {
  color: $secondary;
  background: $secondary-0;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: $white;
  background: $primary;
}

.card-activity-disable {
  background-color: #f3f7f9;

  i.cursor-pointer {
    display: none;
  }
}

// PDF
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
.simulation-pdf {
  width: 595px;
  font-family: 'Poppins', sans-serif;

  .information {
    padding-bottom: 12px;
  }

  .headerPdf {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    background-color: #00b07b;
    padding: 14px 32px 14px 32px;
  }

  .header-presimulador {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    background-color: #00b07b;
    padding: 22px 32px 21px 32px;
  }

  .counselor {
    border-bottom-left-radius: 20px;
    font-size: 8px;
    color: #ffffff;
    background-color: #283261;
    padding: 5px 30px;

    .row div {
      margin-right: 5px;
    }
  }

  .header-title {
    font-size: 18px;
    padding-bottom: 4px;
    font-weight: 700;
  }

  .header-subtitle {
    font-size: 10px;
  }

  .header-subtitle-data {
    font-size: 10px;
    font-weight: 600;
    &.client {
      font-weight: 700;
    }
  }

  .logo {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    text-align: end;
    padding-bottom: 7px;
  }

  .subtitle-logo {
    font-size: 8px;
    font-weight: 600;
    line-height: 9.6px;
    text-align: right;
  }

  .summary {
    height: 660px;
    padding-top: 12px;
  }

  .content {
    padding: 0 30px;
  }

  .content-presimulador {
    padding: 0 30px;
  }

  .body-title {
    font-size: 10px;
    font-weight: 700;
    border-radius: 8px;
    background: #e5e5e5;
    padding: 8px 16px;
    color: #283261;
  }

  .body-subtitle {
    font-size: 10px;
    font-weight: 600;
    border-bottom: 1px solid #ecedee;
    padding-bottom: 5px;
    margin-bottom: 15px;
    color: #007b80;
  }

  .data {
    padding: 15px 15px 5px 15px;
    font-weight: 600;
  }

  .data-title {
    font-size: 8px;
    color: #696c80;
    line-height: 12px;
  }

  .data-items {
    padding-bottom: 12px;
    font-size: 10px;
    color: #141414;
    line-height: 15px;
  }

  .box {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    box-sizing: border-box;
    height: 75px;
  }

  .box-presimulador {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    box-sizing: border-box;
    height: 90px;
    padding-bottom: 20px;
    padding-top: 40px;
  }

  .payment {
    height: 652px;
    padding: 0px 30px;
  }

  table {
    border: 1px solid #c5c8cb;
    border-radius: 4px;

    thead {
      background-color: #e8e8e8;
      text-align: left;
    }

    tr {
      height: 22px;
      border: 0.5px solid #e8e8e8;
    }

    th {
      font-size: 6.5px;
      font-weight: 600;
      border: none;
      color: #003c49;
      padding: 0;
    }

    td {
      color: #52575c;
      font-size: 6.5px;
      border: none;
      text-align: left;
    }

    .align {
      text-align: center;
    }

    tr.mat-footer-row {
      font-weight: bold;
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type {
      padding-left: 10px;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type {
      padding-right: 10px;
      width: 65px;
    }
  }

  .footerPdf {
    font-size: 6px;
    font-weight: 500;
    line-height: 11px;
    padding: 6px 32px 18.7px 32px;
    color: #788087;
  }
}

.hidden-component {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.mat-tab-labels .mat-tab-label {
  opacity: 1;
  color: #c5c8cb;
}

.mat-tab-label.mat-tab-label-active {
  color: #007b80;
}

.drag-drop-files {
  border: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 180px 70px;
}

.drag-files {
  border: none;
  width: 100%;
  justify-content: center;
  display: block;
}

.document-list {
  padding: 4px 18px 8px;
  min-height: 350px;
}

.simulation-subtitle {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  @extend .tracking-wide;
}

@media (min-width: $grid-m-min) {
  body {
    background-color: #f6f8fb;
  }

  button.return-mobile {
    display: none;
  }

  a.return-normal {
    display: block;
  }

  .layout {
    flex-direction: row;
  }

  .main-content {
    padding: 50px 35px;
    background-color: inherit;
  }

  .row {
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .login {
    flex-direction: row;

    &__intro {
      flex: 1;
      padding: 23px 60px;

      &__brand {
        margin-bottom: 103px;

        img {
          width: 140px;
        }
      }

      &__info {
        margin: 0 auto;
        max-width: 352px;

        .title {
          margin-bottom: 13px;
          font-size: 2.5rem;
          line-height: 1;
        }

        p {
          margin-right: 60px;
        }
      }

      &__img {
        display: block;
      }
    }

    &__diagonal {
      width: 0;
      height: 0;
      border-top: calc(100vh - 82px) solid $primary-300;
      border-right: 50px solid transparent;
    }

    &__form {
      justify-content: center;
      padding: 0px 84px;

      .title {
        margin-bottom: 85px;
        font-size: 1.5rem;
        line-height: 2rem;
      }

      .subtitle {
        margin-bottom: 15px;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .google-btn {
        margin-bottom: 65px;
        width: max-content;
      }
    }
  }

  .recover {
    .login__intro {
      border-bottom-right-radius: 90px;
    }

    .login__intro__content {
      display: block;
    }
  }

  .sidebar {
    display: block;
    min-width: 260px;
    height: 100%;
    min-height: 100vh;

    &__brand {
      display: block;
      padding-top: 23px;
      padding-bottom: 23px;
      text-align: center;

      img {
        width: 140px;
        height: auto;
      }

      button {
        display: none;
      }
    }

    .nav-list {
      position: relative;
      z-index: auto;
      width: auto;
    }

    &__menu {
      margin: 30px 20px;

      &__section {
        margin-bottom: 10px;
        border-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__item {
        padding: 14px 24px;
        border-radius: 10px;
      }
    }

    &__submenu {
      margin-left: 37px;

      &__item {
        padding: 14px 24px;
        border-radius: 10px;
      }
    }

    &__user {
      padding: 20px 19px;
    }

    &__logout {
      button.mat-stroked-button {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  .prequalifications-search {
    button.mat-flat-button {
      margin-left: 16px;
    }
  }

  .prequalifications-button-top {
    .btn-icon {
      display: block;
      position: absolute;
      z-index: 1;
      top: -30px;
      right: -15px;
    }

    .mat-stroked-button {
      display: none;
    }
  }

  .prequalifications-button-bottom {
    .btn-icon {
      display: block;
      position: absolute;
      z-index: 1;
      right: -15px;
      bottom: -35px;
    }

    .mat-stroked-button {
      display: none;
    }
  }

  .table-wrapper {
    mat-paginator {
      justify-content: end;
      padding-right: 40px;
    }
  }

  .container {
    padding: 40px 60px !important;
    background: $white;
    box-shadow: 0px 12px 26px rgb(16 30 115 / 6%);
    border-radius: 8px;

    .subtitle {
      margin-bottom: 30px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 400;
    }

    .mat-flat-button {
      width: auto;
    }
  }

  .rule {
    &__header {
      &__code {
        margin-right: 12px;
        width: 40px;
        height: 40px;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      &__content {
        flex-direction: row;
        width: 100% !important;

        &__description {
          .name {
            font-size: 1rem;
            line-height: 1.5rem;
          }

          .description {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
      }
    }

    &__detail {
      margin-left: 50px;
    }
  }

  .user-form,
  .application-form {
    .credit-info {
      &__monthly-fee {
        margin-bottom: 0;
      }
    }

    .print-float {
      position: absolute;
      top: 40px;
      right: 40px;
    }
  }
}

@media print {
  button.mat-flat-button,
  button.mat-stroked-button,
  a.mat-flat-button,
  a.mat-stroked-button {
    display: none;
  }

  .sidebar {
    display: none;
  }
}

.grecaptcha-badge {
  display: none;
  visibility: hidden;
}
