.chip-box {
  display: flex;
  flex-wrap: wrap;
  .chip {
    margin: 10px 20px 10px 0;
    height: $chip-height;
    background: rgba(80, 80, 80, 0.15);
    border-radius: $chip-border-radius;
    padding: 0 20px;
    cursor: pointer;
    color: $chip-color;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &.active,
    &.active:hover {
      background-color: rgba($primary, 0.15);
      color: $primary;
      font-weight: bold;
    }
  }
}
