.vivela-reel {
  &__property-features {
    margin-right: 20;
  }
  &__information {
    margin-left: 20;
    margin-bottom: 40;
  }
  &__price {
    color: $on-primary-high-emphasis;
    font-size: 25;
  }
  &__address {
    color: $on-primary-high-emphasis;
    font-size: 15;
  }
}
