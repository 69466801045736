.vv-radio-button {
  display: flex;
  align-items: center;
  .mat-error {
    padding: 0 2em;
    font-size: 75%;
  }
  .subtitle {
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 17px;
    margin-top: 10px;
    width: 100%;
  }
}

.vv-radio-button-label {
  margin-bottom: 20px;
}

.mat-radio-group {
  display: flex;
  align-items: center;
  height: 56px;
  flex: 1;
  justify-content: space-around;
}

.mat-radio-button {
  flex: 1;
  padding: 0 15px;
}

.radio-button-invalid {
  .mat-radio-outer-circle {
    border-color: $error;
  }
}
