.text-light {
  font-weight: 300;
}
.text-regular {
  font-weight: 400;
}
.text-medium {
  font-weight: 500;
}
.text-semibold {
  font-weight: 600;
}
.text-bold {
  font-weight: 700;
}
.text-extrabold {
  font-weight: 800;
}
