.bottom-navigation-bar {
  padding: 10 20;
  width: 100%;
  height: 60;
  border-top-left-radius: 15;
  border-top-right-radius: 15;
  background-color: $background;
}

.bottom-navigation-item {
  width: 60;
  height: 40;
  color: $on-background-high-emphasis;
  &__icon {
    margin: 0;
  }
  &__text {
    font-size: 12;
    @extend .text-regular;
  }
}
