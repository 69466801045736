/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzY3OWU4NSIsIj9lcjwjYmNkNGM5IiwiO2VyPCM0NzZmNWR$LCIlPmBePCM2NzllODUiLCI~ZXI8I2JjZDRjOSIsIjtlcjwjNDc2ZjVkfiwid2Fybj5gXjwjZTc0ZjQ4IiwiP2VyPCNmOGNhYzgiLCI7ZXI8I2RkMzYzMH4sIj9UZXh0PCM1MjU3NWMiLCI~PTwjZmZmZmZmIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwibGluZUhlaWdodD4xMzEsInNpemU$OTZ9LGBAPCgtMyIsImxpbmVIZWlnaHQ$ODIsInNpemU$NjB9LGBAPCgtMiIsImxpbmVIZWlnaHQ$NTYsInNpemU$NDh9LGBAPCgtMX4sYEA8aGVhZGxpbmUiLCJsaW5lSGVpZ2h0PjI4fSxgQDx0aXRsZSIsImxpbmVIZWlnaHQ$MjN9LGBAPHN1YiktMiIsImxpbmVIZWlnaHQ$MTl9LGBAPHN1YiktMSIsImxpbmVIZWlnaHQ$MTYsInNpemU$MTR9LGBAPGJvZHktMiIsInZhcmlhbnQ8cmVndWxhciIsImxpbmVIZWlnaHQ$MTksInNpemU$MTZ9LGBAPGJvZHktMSIsImxpbmVIZWlnaHQ$MTZ9LGBAPGJ1dHRvbiIsImxpbmVIZWlnaHQ$MTZ9LGBAPGNhcHRpb24iLCJsaW5lSGVpZ2h0PjEwfSxgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/

@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

$fontConfig: (
  display-4: mat-typography-level(96px, 131px, 300, $font-family, -0.0156em),
  display-3: mat-typography-level(60px, 82px, 400, $font-family, -0.0083em),
  display-2: mat-typography-level(48px, 56px, 400, $font-family, 0em),
  display-1: mat-typography-level(34px, 40px, 400, $font-family, 0.0074em),
  headline: mat-typography-level(24px, 28px, 400, $font-family, 0em),
  title: mat-typography-level(20px, 23px, 500, $font-family, 0.0075em),
  subheading-2: mat-typography-level(16px, 19px, 400, $font-family, 0.0094em),
  subheading-1: mat-typography-level(14px, 16px, 500, $font-family, 0.0071em),
  body-2: mat-typography-level(16px, 19px, 400, $font-family, 0.0156em),
  body-1: mat-typography-level(14px, 16px, 400, $font-family, 0.0179em),
  button: mat-typography-level(14px, 16px, 500, $font-family, 0.0893em),
  caption: mat-typography-level(12px, 10px, 400, $font-family, 0.0333em),
  input: mat-typography-level(16px, 1, 400, $font-family, 0.0075em),
);

// Foreground Elements

// Light Theme Text
$dark-text: $white;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: $secondary;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: $primary;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: $secondary;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($primary, 0.04);
$light-bg-alpha-12: rgba($primary, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: $primary;
  --primary-lighter-color: $primary-100;
  --primary-darker-color: $primary-400;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: $primary,
  lighter: $primary-100,
  darker: $primary-400,
  200: $primary,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: $primary;
  --accent-lighter-color: $primary-100;
  --accent-darker-color: $primary-400;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: $primary,
  lighter: $primary-100,
  darker: $primary-400,
  200: $primary,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: $error;
  --warn-lighter-color: #f8cac8;
  --warn-darker-color: #dd3630;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: $error,
  lighter: #f8cac8,
  darker: #dd3630,
  200: $error,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);
$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
// .mat-raised-button,
// .mat-stroked-button,
// .mat-flat-button {
//   padding: 0 1.15em;
//   margin: 0 0.65em;
//   min-width: 3em;
//   line-height: 41.6px;
// }

// .mat-standard-chip {
//   padding: 0.5em 0.85em;
//   min-height: 2.5em;
// }

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}
