$yiq-contrasted-threshold: 150;

// Grid breakpoints
$grid-xs-min: 0;
$grid-s-min: 576px;
$grid-m-min: 768px;
$grid-l-min: 992px;
$grid-xl-min: 1200px;

$padding-x: 15px;

// Buttons
$btn-height: 44;
$btn-font-size: 14;
$btn-border-radius: 50;

// Labels
$label-color: #a0a4a8;

// Inputs
$input-border-color: #656566 !default;
$input-margin-top: 10px !default;
$input-margin-bottom: 20px !default;
$input-color: #505050;

// select
$select-btn-background: $white;
$select-btn-padding: 14 20;
$select-btn-color: #9e9fa1 !default;
$select-font-size: 14;
$select-btn-border-color: #656566 !default;
$select-btn-border-raduis: 8;
$select-btn-icon-size: 30;
$select-btn-icon-background: #ededed;
$select-space-btn-options: 7;
$select-options-background: white;
$select-options-border-radius: 8;
$select-options-border-color: #e7e7e7;
$select-options-separator-color: #f2f7ff;
$select-option-color: #4e5057;
$select-option-height: 50;
$select-option-padding: 15;
$select-option-active-background-color: #e7e7e7;

// chips
$chip-height: 40px;
$chip-border-radius: 50px;
$chip-color: #505050;

$separator-color: $secondary-0;

// separator
.separator {
  border-bottom-width: size(1);
  border-bottom-style: solid;
  border-bottom-color: $separator-color;
}

.separator--grey {
  border-bottom-width: size(1);
  border-bottom-style: solid;
  border-bottom-color: $separator-color;
}

.separator--grey-light {
  border-bottom-width: size(2);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
}

.separator--secondary-100 {
  border-bottom-width: size(1);
  border-bottom-style: solid;
  border-bottom-color: $secondary-100;
}

// Mobile old colors

$mobile-primary: #929bac;
$primary-light: #dddddd;

$on-primary-high-emphasis: #ffffff;
// $on-primary-medium-emphasis: rgba(255, 255, 255, 0.74);
// $on-primary-disabled: rgba(22, 38, 42, 0.38);

$mobile-secondary: #ff560a;

$secondary-light: #fff3ee;

$on-secondary-high-emphasis: #ffffff;
// $on-secondary-medium-emphasis: rgba(255, 255, 255, 0.6);
// $on-secondary-disabled: rgba(255, 255, 255, 0.38);

$surface: #ffffff;

$on-surface-high-emphasis: #505050;
$on-surface-high-emphasis: rgba(#505050, 0.8);
// $on-surface-disabled: rgba(22, 38, 42, 0.2);

$mobile-error: #ff0000;

$error-light: #ffefef;

$on-error: #ffffff;

$mobile-success: #309d69;

$on-success: #ffffff;

// $outline: rgba(22, 38, 42, 0.12);

$background: $white;

$on-background-high-emphasis: #505050;
$on-background-medium-emphasis: rgba(#505050, 0.8);
// $on-background-disabled: rgba(22, 38, 42, 0.38);

// $complementary-cyan: #74dbc6;
// $complementary-yellow: #f8bf13;
