.vivela-property-features {
  &--horizontal {
    .vivela-property-features__item {
      margin-right: 20;
    }
  }
  &--vertical {
    flex-direction: column;
    .vivela-property-features__item {
      margin-bottom: 10;
    }
  }
  &__item {
    flex-direction: column;
  }
  &__avatar {
    justify-content: center;
  }
  &__text {
    color: $background;
    font-size: 10;
    text-align: center;
    @extend .text-medium;
  }
}
