.textview {
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  font-size: 20px;
  &__label {
    margin-right: 5px;
  }
}
