$colors: (
  'primary': $primary,
  'secondary': $secondary,
);

.switch {
  display: flex;
  align-items: center;
  &__btn[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    margin: 0;
    + label {
      cursor: pointer;
      text-indent: -9999px;
      width: 50px;
      height: 25px;
      background: #dddddd;
      display: block;
      border-radius: 100px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 21px;
        height: 21px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
        box-shadow: 0px 4px 4px rgba(34, 30, 120, 0.25);
      }
    }
    &:checked {
      + label {
        &:after {
          left: calc(100% - 2px);
          transform: translateX(-100%);
        }
      }
      @each $color, $variable in $colors {
        &.#{$color} + label {
          background-color: $variable;
        }
      }
    }
  }
  &__label {
    margin-left: 10px;
  }
}
