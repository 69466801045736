.cdk-overlay-dark-backdrop,
.modal-dark-backdrop {
  @extend .bg-black;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
.modal-backdrop.modal-backdrop-showing {
  opacity: 0.8;
}

.cdk-global-overlay-wrapper {
  .vivela-error-dialog-panel {
    .mat-dialog-container {
      @extend .radius-15;
    }
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.modal {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 100%;
    max-width: 900px;
    z-index: 1000;
    pointer-events: auto;
    @extend .p-15;
    @extend .bg-white;
    @extend .radius-15;
  }

  &--sm &__content {
    max-width: 560px;
    @extend .mx-16;
  }

  &__close {
    display: flex;
    justify-content: flex-end;

    .btn-icon {
      border: 1px solid $secondary-0;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .mb-40;
    @extend .text-center;

    .file-loading,
    .loading {
      @extend .mt-50;
    }

    .file-loading,
    .file-success,
    .file-delete,
    .success {
      @extend .text-secondary;
    }

    .file-error,
    .error {
      @extend .text-error;
    }

    .material-icons {
      @extend .w-36;
      @extend .h-32;
      @extend .text-3xl;
    }

    .mat-progress-bar-buffer {
      background-color: #c5c5c5;
    }

    .mat-progress-bar .mat-progress-bar-fill::after {
      animation: running;
      width: 122px;
    }

    .file-tittle-loading,
    .file-tittle-success,
    .file-tittle-error,
    .file-tittle-delete,
    .title {
      @extend .my-5;
      @extend .text-xl;
      @extend .text-bold;
    }

    .detail,
    .message {
      @extend .text-sm;
      @extend .text-regular;
    }

    .buttons {
      display: flex;
      justify-content: center;

      button {
        @extend .mx-10;
        @extend .mb-0;
      }
    }

    .btn {
      border: none;
      @extend .text-white;
      @extend .bg-primary-300;
    }

    button {
      display: initial;
      @extend .mt-25;
      @extend .py-5;
      @extend .px-32;
      @extend .text-sm;
      @extend .text-medium;
      @extend .tracking-wider;
      @extend .text-uppercase;
      @extend .radius-5;
    }
  }
}

@media (min-width: $grid-m-min) {
  .cdk-overlay-dark-backdrop,
  .modal-dark-backdrop {
    background-color: #515151;
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
  .modal-backdrop.modal-backdrop-showing {
    opacity: 0.7;
  }

  .modal {
    &__body {
      margin: 45px 30px 50px 30px;

      .file-loading,
      .loading {
        margin-top: 40px;
        margin-bottom: 60px;
      }

      button {
        margin-top: 40px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
