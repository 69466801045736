.tracking-tightest {
  letter-spacing: -0.078em;
}
.tracking-tighter {
  letter-spacing: -0.03em;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-normal {
  letter-spacing: 0em;
}
.tracking-wide {
  letter-spacing: 0.03em;
}
.tracking-wider {
  letter-spacing: 0.078em;
}
.tracking-widest {
  letter-spacing: 0.125em;
}
