// shared across all platforms and apps
@import '@vivela/xplat-scss/index';

/**
 * The following are web specific (used with any web app targets)
 */

// web specific variables
@import 'variables';

// basic
@import 'tags';

// bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/utilities/display';
@import 'bootstrap/scss/utilities/flex';
@import 'bootstrap/scss/utilities/sizing';
// @import 'bootstrap/scss/utilities';
// @import 'bootstrap/scss/modal';
@import 'bootstrap/scss/utilities/overflow';

// Components
@import 'components';

// Utils
@import 'utils';
