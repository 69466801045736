$space-stops: (
  '0': size(0),
  '5': size(5),
  '8': size(8),
  '15': size(15),
  '90': size(90),
);

@each $key, $val in $space-stops {
  .radius-#{$key} {
    border-radius: #{$val};
  }
  .rounded-br-#{$key} {
    border-bottom-right-radius: #{$val};
  }
}

.rounded-circle {
  border-radius: 100%;
}
