.border-1 {
  border-style: solid;
  border-width: size(1);
}

.border-2 {
  border-style: solid;
  border-width: size(2);
}

.border-3 {
  border-style: solid;
  border-width: size(3);
}

.border-4 {
  border-style: solid;
  border-width: size(4);
}
