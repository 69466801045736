/**
 * Shared across all platforms and apps
 * You may define a set of global variables accessible across entire workspace here
 */

// could use a base theme here, for example:
// @import '~@angular/material/theming';

// Platform
$platform: 'web' !default;

// Typography
$font-family: 'Roboto';

// Colors
$primary: #007b80;
$secondary: #003c49;
$success: #16d090;
$error: #e74f48;
$warning: #ffaa3f;
$info: #ead627;

$white: #ffffff;
$black: #000000;

// Success variants
$success-0: #eaf9f2;

// Primary variants
$primary-0: #b7ffa0;
$primary-100: #7fe55f;
$primary-200: #00ad86;
$primary-300: #007b80;
$primary-400: #003c49;
$primary-500: #072f37;
$primary-600: #000000;

// Secondary variants
$secondary-0: #ecedee;
$secondary-100: #c5c8cb;
$secondary-200: #9fa4a9;
$secondary-300: #788087;
$secondary-400: #565b60;
$secondary-500: #33373a;
$secondary-600: #111213;

$theme-colors: (
  'primary': $primary,
  'primary-0': $primary-0,
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-300': $primary-300,
  'primary-400': $primary-400,
  'primary-500': $primary-500,
  'primary-600': $primary-600,
  'secondary': $secondary,
  'secondary-0': $secondary-0,
  'secondary-100': $secondary-100,
  'secondary-200': $secondary-200,
  'secondary-300': $secondary-300,
  'secondary-400': $secondary-400,
  'secondary-500': $secondary-500,
  'secondary-600': $secondary-600,
  'success': $success,
  'success-0': $success-0,
  'error': $error,
  'warning': $warning,
  'info': $info,
  'white': $white,
  'black': $black,
);
