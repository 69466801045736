.list {
  &__item {
    display: flex;
    align-items: center;
    @extend .mt-16;
    @extend .text-sm;
    @extend .text-regular;

    &__decorator {
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .w-15;
      @extend .h-15;
      @extend .text-xs;
      @extend .text-white;
      @extend .bg-primary;
      @extend .rounded-circle;

      .icon {
        @extend .icon--sm;
      }
    }

    &__decorator + &__name {
      margin-left: 10px;
    }

    &__value {
      margin-left: 20px;
    }

    &__name + &__value {
      margin-left: 5px;
    }
  }
}

@media (min-width: $grid-m-min) {
  .list {
    &__item {
      margin-top: 28px;
      font-size: 1rem; /* 16px */
      line-height: 1.5rem; /* 24px */

      &:first-child {
        margin-top: 0;
      }

      &__decorator {
        width: 25px;
        height: 25px;

        .icon {
          font-size: 24px;
        }
      }
    }
  }
}
