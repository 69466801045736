$space-stops: (
  '0': size(0),
  '5': size(5),
  '10': size(10),
  '15': size(15),
  '20': size(20),
  '25': size(25),
  '30': size(30),
  '32': size(32),
  '35': size(35),
  '36': size(36),
  '40': size(40),
  '45': size(45),
  '50': size(50),
  '55': size(55),
  '60': size(60),
  '65': size(65),
  '70': size(70),
  '75': size(75),
  '80': size(80),
  '85': size(85),
  '90': size(90),
  '95': size(95),
  '100': size(100),
  'auto': auto,
);

@each $key, $val in $space-stops {
  .w-#{$key} {
    width: #{$val};
  }
  .h-#{$key} {
    height: #{$val};
  }
}
