.file {
  text-align: left;
  padding: 10px 0px 10px 0px;
}
.photo {
  font-size: 15px;
  padding-bottom: 10px;
}
.upload-file {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 7px;
  width: 100%;
}
.upload-file img {
  max-width: 300px;
  width: 100%;
}
