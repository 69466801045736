.text-field {
  //Dimensions
  width: 100%;
  height: 63;
  //Typography
  @extend .text-medium;
  &__border {
    //Colors
    border-color: $primary;
    //Dimensions
    height: 44;
    width: 100%;
    //Position
    top: 7;
    //Box Sizing
    border-width: 1;
    border-radius: 8;
  }
  &__label {
    //Colors
    background-color: $background;
    color: $on-background-high-emphasis;
    //Typography
    font-size: 14;
    line-height: 14;
    //Position
    top: 20;
    left: 20;
  }
  &__leading-icon {
    //Colors
    color: $on-background-high-emphasis;
    //Box Sizing
    margin-right: 8;
  }
  &__trailing-icon {
    //Colors
    color: $on-background-high-emphasis;
    //Box Sizing
    margin-left: 8;
  }
  &__input-container {
    //Dimensions
    width: 100%;
    // Position
    top: 19;
    // Box Sizing
    padding: 0 20;
  }
  &__input {
    //Colors
    color: $on-background-high-emphasis;
    background-color: transparent;
    border-bottom-color: transparent;
    //Typography
    font-size: 14;
    //Dimensions
    width: 100%;
    //Box Sizing
    margin: 0;
    padding: 0;
    border-bottom-width: 1;
  }
  &__helper-text {
    //Colors
    color: $on-background-high-emphasis;
    //Typography
    font-size: 10;
    line-height: 10;
    //Position
    top: 51;
    left: 20;
  }
}

.text-field--active {
  & .text-field__border {
    //Colors
    border-color: $secondary;
    //Box Sizing
    border-width: 2;
  }
  & .text-field__label {
    //Colors
    color: $secondary;
  }
  & .text-field__helper-text {
    //Colors
    color: $secondary;
  }
}
