.badge {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;
}

.badge__circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    color: $white;
    .badge__circle {
      background: $value;
    }
  }
  .badge-outline-#{$color} {
    color: $value;
    .badge__circle {
      background: $value;
    }
  }
}

.badge--small {
  font-size: 12px;
  line-height: 16px;
  min-width: 100px;

  .badge__circle {
    height: 7px;
    width: 7px;
  }
}
