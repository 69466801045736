@each $key, $val in $theme-colors {
  .bg-#{$key} {
    background-color: #{$val};
  }
}

.bg-inherit {
  background-color: inherit;
}

.bg-transparent {
  background-color: transparent;
}
