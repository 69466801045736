.vivela-stepper-container {
  padding: 16 24;
}

.vivela-stepper-line {
  height: 1;
  background-color: $primary;
  vertical-align: center;
}

.vivela-stepper {
}

.vivela-step {
  width: 40;
  height: 40;
  border-radius: 24;
  text-align: center;
  vertical-align: middle;
  border-width: 1;
  border-color: $primary;
  background-color: $background;

  &.--active {
    border-color: $secondary;

    Label {
      color: $secondary;
    }
  }

  &.--completed {
    border-color: $secondary;
    background-color: $secondary;

    Label {
      color: $on-secondary-high-emphasis;
    }
  }

  Label {
    @extend .text-medium;
    font-size: 16;
    color: $on-background-high-emphasis;
  }
}
