.vivela-favorite-button {
  padding: 6;
  border-radius: 15;
}

.vivela-favorite-button--primary {
  background: $on-primary-high-emphasis;
  Label {
    color: $primary;
  }
  &.vivela-favorite-button--active {
    background: $on-primary-high-emphasis;
    Label {
      color: $primary;
    }
  }
}

.vivela-favorite-button--secondary {
  background: $on-secondary-high-emphasis;
  Label {
    color: $secondary;
  }
  &.vivela-favorite-button--active {
    background: $secondary;
    Label {
      color: $on-secondary-high-emphasis;
    }
  }
}
