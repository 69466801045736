.application {
  &__pep-title {
    padding: 20px 40px;
    background: $primary-200;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  &__pep-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#activity-list-container {
  .disabled {
    color: #788087 !important;

    .text-primary-200 {
      color: #788087 !important;
    }
  }
}

#references-container {
  .disabled {
    color: #788087 !important;

    .text-primary-200 {
      color: #788087 !important;
    }
  }
}
