.position-static {
  position: static;
}
.position-fixed {
  position: fixed;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-sticky {
  position: sticky;
}
