.icon {
  text-transform: none;
  font-size: size(24);
  &--xs {
    font-size: size(10);
  }
  &--xsm {
    font-size: size(14);
  }
  &--sm {
    font-size: size(18);
  }
  &--md {
    font-size: size(36);
  }
  &--lg {
    font-size: size(48);
  }
}
