.heading-1 {
  font-size: size(70);
  @extend .text-regular;
}

.heading-2 {
  font-size: size(54);
  @extend .text-regular;
}

.heading-3 {
  @extend .text-6xl;
  @extend .text-light;
}

.heading-4 {
  @extend .text-3xl;
  @extend .text-regular;
}

.heading-5 {
  @extend .text-2xl;
  @extend .text-regular;
}

.heading-6 {
  @extend .text-xl;
  @extend .text-regular;
}

.body-1 {
  @extend .text-base;
  @extend .text-regular;
}

.body-2 {
  @extend .text-sm;
  @extend .text-regular;
}

.body-3 {
  @extend .text-xs;
  @extend .text-regular;
}

.body-4 {
  font-size: size(10);
  @extend .text-regular;
  @extend .tracking-wide;
}
