.vivela-bottom-sheet {
  padding: 20;
  background: $background;
  border-radius: 20 20 0 0;
  height: 250;
  &__title {
    color: $on-background-high-emphasis;
    font-size: 12;
    margin-left: 8;
    @extend .text-medium;
  }
}
