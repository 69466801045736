.icon-button {
  padding: 6;
  border-radius: 50;
  Label {
    color: $on-background-high-emphasis;
  }
}

.icon-button--primary {
  background-color: $primary;
  border-color: $primary;
  Label {
    color: $on-primary-high-emphasis;
  }
}

.icon-button--secondary {
  background-color: $secondary;
  border-color: $secondary;
  Label {
    color: $on-secondary-high-emphasis;
  }
}

.icon-button--outlined {
  background-color: $background;
  border-width: 2;
  &.icon-button--primary {
    Label {
      color: $primary;
    }
  }
  &.icon-button--secondary {
    Label {
      color: $secondary;
    }
  }
}
