.vivela-avatar {
  background: $background;
  color: $on-background-high-emphasis;
  height: 40;
  width: 40;
  border-radius: 20;
  &__image {
    border-radius: 20;
  }
  &__icon {
    margin: 0;
  }
  &__text {
    font-size: 14;
    @extend .text-regular;
  }
}
