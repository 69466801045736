/**
 * The following are shared across all platforms and apps
 */
/**
 * Shared across all platforms and apps
 * You may define a set of global variables accessible across entire workspace here
 */
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
.separator {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ecedee;
}

.separator--grey {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ecedee;
}

.separator--grey-light {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
}

.separator--secondary-100 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c5c8cb;
}

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzY3OWU4NSIsIj9lcjwjYmNkNGM5IiwiO2VyPCM0NzZmNWR$LCIlPmBePCM2NzllODUiLCI~ZXI8I2JjZDRjOSIsIjtlcjwjNDc2ZjVkfiwid2Fybj5gXjwjZTc0ZjQ4IiwiP2VyPCNmOGNhYzgiLCI7ZXI8I2RkMzYzMH4sIj9UZXh0PCM1MjU3NWMiLCI~PTwjZmZmZmZmIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwibGluZUhlaWdodD4xMzEsInNpemU$OTZ9LGBAPCgtMyIsImxpbmVIZWlnaHQ$ODIsInNpemU$NjB9LGBAPCgtMiIsImxpbmVIZWlnaHQ$NTYsInNpemU$NDh9LGBAPCgtMX4sYEA8aGVhZGxpbmUiLCJsaW5lSGVpZ2h0PjI4fSxgQDx0aXRsZSIsImxpbmVIZWlnaHQ$MjN9LGBAPHN1YiktMiIsImxpbmVIZWlnaHQ$MTl9LGBAPHN1YiktMSIsImxpbmVIZWlnaHQ$MTYsInNpemU$MTR9LGBAPGJvZHktMiIsInZhcmlhbnQ8cmVndWxhciIsImxpbmVIZWlnaHQ$MTksInNpemU$MTZ9LGBAPGJvZHktMSIsImxpbmVIZWlnaHQ$MTZ9LGBAPGJ1dHRvbiIsImxpbmVIZWlnaHQ$MTZ9LGBAPGNhcHRpb24iLCJsaW5lSGVpZ2h0PjEwfSxgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjExfQ==
*/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 28px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 23px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 19px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 500 14px / 16px Roboto;
  letter-spacing: 0.0071em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 16px Roboto;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 16px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 400 16px / 19px Roboto;
  letter-spacing: 0.0156em;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 16px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 10px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 96px / 131px Roboto;
  letter-spacing: -0.0156em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 60px / 82px Roboto;
  letter-spacing: -0.0083em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 48px / 56px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 16px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 19px;
}

.mat-chip {
  font-size: 16px;
  font-weight: 400;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 23px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px / 16px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font: 400 16px / 1 Roboto;
  letter-spacing: 0.0075em;
}

.mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1em;
  line-height: 1;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.75em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.25em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.24999em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.75em;
  padding-top: 0.75em;
}

.mat-form-field-label {
  top: 1.25em;
}

.mat-form-field-underline {
  bottom: 1.25em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.6666666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.5em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.25em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.6666666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.24997em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.24996em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.24995em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.5em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.49999em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.75em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.49999em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: 0em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 400;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 23px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 400;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 400 16px / 19px Roboto;
  letter-spacing: 0.0156em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

body {
  --primary-color: $primary;
  --primary-lighter-color: $primary-100;
  --primary-darker-color: $primary-400;
  --text-primary-color: rgba(255, 255, 255, 0.87);
  --text-primary-lighter-color: rgba(255, 255, 255, 0.87);
  --text-primary-darker-color: #003c49;
}

body {
  --accent-color: $primary;
  --accent-lighter-color: $primary-100;
  --accent-darker-color: $primary-400;
  --text-accent-color: rgba(255, 255, 255, 0.87);
  --text-accent-lighter-color: rgba(255, 255, 255, 0.87);
  --text-accent-darker-color: #003c49;
}

body {
  --warn-color: $error;
  --warn-lighter-color: #f8cac8;
  --warn-darker-color: #dd3630;
  --text-warn-color: #003c49;
  --text-warn-lighter-color: rgba(255, 255, 255, 0.87);
  --text-warn-darker-color: #003c49;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007b80;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007b80;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74f48;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #007b80;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #007b80;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74f48;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #007b80;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: #003c49;
  background: #e74f48;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #007b80;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #007b80;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e74f48;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #007b80;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #007b80;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74f48;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: rgba(255, 255, 255, 0.87);
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(255, 255, 255, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: #003c49;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #007b80;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #007b80;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #e74f48;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 60, 73, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #007b80;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #007b80;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74f48;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #007b80;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #007b80;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74f48;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74f48;
  color: #003c49;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: #003c49;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 60, 73, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 123, 128, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 123, 128, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 123, 128, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 123, 128, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 123, 128, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 123, 128, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 123, 128, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 123, 128, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 79, 72, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 79, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 79, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74f48;
  color: #003c49;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 79, 72, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #003c49;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 79, 72, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 79, 72, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #007b80;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #007b80;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #e74f48;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #007b80;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #007b80;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74f48;
}

.mat-focused .mat-form-field-required-marker {
  color: #007b80;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #007b80;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #007b80;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74f48;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #007b80;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #007b80;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74f48;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74f48;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74f48;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74f48;
}

.mat-error {
  color: #e74f48;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #007b80;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #007b80;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74f48;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74f48;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #007b80;
}
.mat-icon.mat-accent {
  color: #007b80;
}
.mat-icon.mat-warn {
  color: #e74f48;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #007b80;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #007b80;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #e74f48;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74f48;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #bcdadc;
}

.mat-progress-bar-buffer {
  background-color: #bcdadc;
}

.mat-progress-bar-fill::after {
  background-color: #007b80;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bcdadc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bcdadc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #007b80;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cfce;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cfce;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74f48;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #007b80;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #007b80;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #e74f48;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007b80;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #007b80;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007b80;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #007b80;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74f48;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74f48;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #007b80;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #007b80;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74f48;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74f48;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #007b80;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 123, 128, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #007b80;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #007b80;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 123, 128, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #007b80;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74f48;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 79, 72, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74f48;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #007b80;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 123, 128, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #007b80;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 123, 128, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74f48;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: #003c49;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 79, 72, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: #003c49;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74f48;
  color: #003c49;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74f48;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74f48;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #007b80;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #007b80;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 202, 200, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74f48;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: #003c49;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #007b80;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #007b80;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 202, 200, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74f48;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: #003c49;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 60, 73, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: #003c49;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #003c49;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #003c49;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-toolbar.mat-accent {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.mat-toolbar.mat-warn {
  background: #e74f48;
  color: #003c49;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #007b80;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007b80;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #007b80;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e74f48;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #303030;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #007b80;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #007b80;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e74f48;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #303030;
  color: white;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: rgba(255, 255, 255, 0.87);
  background: #007b80;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: #003c49;
  background: #e74f48;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #007b80;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #007b80;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: #e74f48;
}
.theme-alternate .mat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-icon-button.mat-primary.mat-button-disabled, .theme-alternate .mat-icon-button.mat-accent.mat-button-disabled, .theme-alternate .mat-icon-button.mat-warn.mat-button-disabled, .theme-alternate .mat-icon-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-primary.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-accent.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-warn.mat-button-disabled, .theme-alternate .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #007b80;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #007b80;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e74f48;
}
.theme-alternate .mat-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: white;
}
.theme-alternate .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: #003c49;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #007b80;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #007b80;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: #e74f48;
}
.theme-alternate .mat-flat-button.mat-primary.mat-button-disabled, .theme-alternate .mat-flat-button.mat-accent.mat-button-disabled, .theme-alternate .mat-flat-button.mat-warn.mat-button-disabled, .theme-alternate .mat-flat-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-raised-button.mat-primary.mat-button-disabled, .theme-alternate .mat-raised-button.mat-accent.mat-button-disabled, .theme-alternate .mat-raised-button.mat-warn.mat-button-disabled, .theme-alternate .mat-raised-button.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-fab.mat-button-disabled.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-primary.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-accent.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-warn.mat-button-disabled, .theme-alternate .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(0, 60, 73, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-alternate .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.theme-alternate .mat-card {
  background: #424242;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #303030;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #303030;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #007b80;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #007b80;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e74f48;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #007b80;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #007b80;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e74f48;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e74f48;
  color: #003c49;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: #003c49;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(0, 60, 73, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(255, 255, 255, 0.87);
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #424242;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  fill: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-table-header,
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content,
.theme-alternate .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-in-range::before {
  background: rgba(0, 123, 128, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 123, 128, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 123, 128, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 123, 128, 0.3);
  }
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 123, 128, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 123, 128, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 123, 128, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 123, 128, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 123, 128, 0.3);
  }
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(231, 79, 72, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(231, 79, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-alternate .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(231, 79, 72, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e74f48;
  color: #003c49;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(231, 79, 72, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #003c49;
}
.theme-alternate .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-alternate .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(231, 79, 72, 0.3);
}
@media (hover: hover) {
  .theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(231, 79, 72, 0.3);
  }
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #007b80;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #007b80;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: #e74f48;
}
.theme-alternate .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #424242;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e74f48;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #007b80;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e74f48;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #007b80;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #007b80;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e74f48;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e74f48;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e74f48;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e74f48;
}
.theme-alternate .mat-error {
  color: #e74f48;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #007b80;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #007b80;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e74f48;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e74f48;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #007b80;
}
.theme-alternate .mat-icon.mat-accent {
  color: #007b80;
}
.theme-alternate .mat-icon.mat-warn {
  color: #e74f48;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #007b80;
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(255, 255, 255, 0.38);
}
.theme-alternate .mat-form-field.mat-accent .mat-input-element {
  caret-color: #007b80;
}
.theme-alternate .mat-form-field.mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: #e74f48;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e74f48;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #424242;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled],
.theme-alternate .mat-menu-item[disabled] .mat-menu-submenu-icon,
.theme-alternate .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-submenu-icon {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-paginator {
  background: #424242;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #244344;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #244344;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #007b80;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #244344;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #244344;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #007b80;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #5e3836;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #5e3836;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e74f48;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #007b80;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #007b80;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: #e74f48;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007b80;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #007b80;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007b80;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #007b80;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e74f48;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e74f48;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #424242;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #007b80;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e74f48;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e74f48;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #424242;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #007b80;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 123, 128, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #007b80;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #007b80;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 123, 128, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #007b80;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e74f48;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(231, 79, 72, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e74f48;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-primary .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb,
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #007b80;
}
.theme-alternate .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 123, 128, 0.2);
}
.theme-alternate .mat-slider.mat-accent .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb,
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #007b80;
}
.theme-alternate .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 123, 128, 0.2);
}
.theme-alternate .mat-slider.mat-warn .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb,
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e74f48;
}
.theme-alternate .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: #003c49;
}
.theme-alternate .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(231, 79, 72, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover:not([aria-disabled]), .theme-alternate .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.theme-alternate .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon {
  color: #003c49;
}
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-alternate .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e74f48;
  color: #003c49;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e74f48;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e74f48;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #424242;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c6c6c6;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #007b80;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #007b80;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 202, 200, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e74f48;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: #003c49;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #007b80;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(127, 229, 95, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #007b80;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 202, 200, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e74f48;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: #003c49;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 60, 73, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: #003c49;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #003c49;
  opacity: 0.4;
}
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.theme-alternate .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #003c49;
  opacity: 0.12;
}
.theme-alternate .mat-toolbar {
  background: #212121;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #007b80;
  color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-toolbar.mat-warn {
  background: #e74f48;
  color: #003c49;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.theme-alternate .mat-tree {
  background: #424242;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

.bg-primary, .sidebar .nav-list, .sidebar, .list__item__decorator {
  background-color: #007b80;
}

.bg-primary-0, .sidebar__menu__item:hover, .sidebar__menu__item.active {
  background-color: #b7ffa0;
}

.bg-primary-100 {
  background-color: #7fe55f;
}

.bg-primary-200 {
  background-color: #00ad86;
}

.bg-primary-300, .login__intro, .modal__body .btn {
  background-color: #007b80;
}

.bg-primary-400, .sidebar__submenu__item:hover, .sidebar__submenu__item.active {
  background-color: #003c49;
}

.bg-primary-500 {
  background-color: #072f37;
}

.bg-primary-600 {
  background-color: #000000;
}

.bg-secondary {
  background-color: #003c49;
}

.bg-secondary-0 {
  background-color: #ecedee;
}

.bg-secondary-100 {
  background-color: #c5c8cb;
}

.bg-secondary-200 {
  background-color: #9fa4a9;
}

.bg-secondary-300 {
  background-color: #788087;
}

.bg-secondary-400 {
  background-color: #565b60;
}

.bg-secondary-500 {
  background-color: #33373a;
}

.bg-secondary-600 {
  background-color: #111213;
}

.bg-success {
  background-color: #16d090;
}

.bg-success-0 {
  background-color: #eaf9f2;
}

.bg-error {
  background-color: #e74f48;
}

.bg-warning {
  background-color: #ffaa3f;
}

.bg-info {
  background-color: #ead627;
}

.bg-white, .table-wrapper, .sidebar__user__avatar, .login, body, .modal__content, .card, .google-btn {
  background-color: #ffffff;
}

.bg-black, .cdk-overlay-dark-backdrop,
.modal-dark-backdrop {
  background-color: #000000;
}

.bg-inherit, .table-wrapper table,
.table-wrapper table.mat-table {
  background-color: inherit;
}

.bg-transparent, .container, .mat-table,
.mat-paginator {
  background-color: transparent;
}

.border-primary {
  border-color: #007b80;
}

.border-primary-0 {
  border-color: #b7ffa0;
}

.border-primary-100 {
  border-color: #7fe55f;
}

.border-primary-200 {
  border-color: #00ad86;
}

.border-primary-300 {
  border-color: #007b80;
}

.border-primary-400 {
  border-color: #003c49;
}

.border-primary-500 {
  border-color: #072f37;
}

.border-primary-600 {
  border-color: #000000;
}

.border-secondary {
  border-color: #003c49;
}

.border-secondary-0 {
  border-color: #ecedee;
}

.border-secondary-100 {
  border-color: #c5c8cb;
}

.border-secondary-200 {
  border-color: #9fa4a9;
}

.border-secondary-300 {
  border-color: #788087;
}

.border-secondary-400 {
  border-color: #565b60;
}

.border-secondary-500 {
  border-color: #33373a;
}

.border-secondary-600 {
  border-color: #111213;
}

.border-success {
  border-color: #16d090;
}

.border-success-0 {
  border-color: #eaf9f2;
}

.border-error {
  border-color: #e74f48;
}

.border-warning {
  border-color: #ffaa3f;
}

.border-info {
  border-color: #ead627;
}

.border-white {
  border-color: #ffffff;
}

.border-black {
  border-color: #000000;
}

.radius-0, .sidebar__submenu__item, .sidebar__menu__item {
  border-radius: 0px;
}

.rounded-br-0, .recover .login__intro {
  border-bottom-right-radius: 0px;
}

.radius-5, .modal__body button, .google-btn {
  border-radius: 5px;
}

.rounded-br-5 {
  border-bottom-right-radius: 5px;
}

.radius-8, .table-wrapper, .card {
  border-radius: 8px;
}

.rounded-br-8 {
  border-bottom-right-radius: 8px;
}

.radius-15, .modal__content, .cdk-global-overlay-wrapper .vivela-error-dialog-panel .mat-dialog-container {
  border-radius: 15px;
}

.rounded-br-15 {
  border-bottom-right-radius: 15px;
}

.radius-90 {
  border-radius: 90px;
}

.rounded-br-90, .login__intro {
  border-bottom-right-radius: 90px;
}

.rounded-circle, .rule__header__code, .sidebar__user__avatar, .list__item__decorator {
  border-radius: 100%;
}

.border-1 {
  border-style: solid;
  border-width: 1px;
}

.border-2 {
  border-style: solid;
  border-width: 2px;
}

.border-3 {
  border-style: solid;
  border-width: 3px;
}

.border-4 {
  border-style: solid;
  border-width: 4px;
}

.cursor-pointer, .table-wrapper .content .mat-row,
.table-wrapper .table-content .mat-row, .sidebar__submenu__item, .sidebar__menu__item, .sidebar__brand img, .login__intro__brand img, .google-btn {
  cursor: pointer;
}

.text-xs, .rule__header, .sidebar__submenu__item, .list__item__decorator, .body-3 {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}

.text-sm, .rule__detail ul li, .table-wrapper table th,
.table-wrapper table td,
.table-wrapper table.mat-table th,
.table-wrapper table.mat-table td, .sidebar__user__info, .sidebar__menu__item__text, .login__form .subtitle, .login__intro__info p, .row p, .list__item, .modal__body button, .modal__body .detail,
.modal__body .message, .google-btn p, .body-2, a {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-base, .container .subtitle, .sidebar__user__avatar__text, .card.main-card .summary-wrapper .subtitle,
.card.main-card .summary-wrapper .badge, .body-1 {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl, .modal__body .file-tittle-loading,
.modal__body .file-tittle-success,
.modal__body .file-tittle-error,
.modal__body .file-tittle-delete,
.modal__body .title, .card.main-card .summary-wrapper .title, .card__header, .heading-6 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl, .heading-5 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-3xl, .login__form .title, .modal__body .material-icons, .heading-4 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.063rem; /* 33px */
  line-height: 2.375rem; /* 38px */
}

.text-5xl, .login__intro__info .title {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.text-6xl, .heading-3 {
  font-size: 2.5rem; /* 40px */
  line-height: 1;
}

.text-7xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}

.text-8xl, .user-form .credit-info__monthly-fee,
.application-form .credit-info__monthly-fee {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}

.text-9xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}

.text-10xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}

.position-static {
  position: static;
}

.position-fixed {
  position: fixed;
}

.position-absolute, .sidebar .nav-list {
  position: absolute;
}

.position-relative, .table-wrapper {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.w-0 {
  width: 0px;
}

.h-0 {
  height: 0px;
}

.w-5 {
  width: 5px;
}

.h-5 {
  height: 5px;
}

.w-10 {
  width: 10px;
}

.h-10 {
  height: 10px;
}

.w-15, .list__item__decorator {
  width: 15px;
}

.h-15, .list__item__decorator {
  height: 15px;
}

.w-20 {
  width: 20px;
}

.h-20 {
  height: 20px;
}

.w-25 {
  width: 25px;
}

.h-25 {
  height: 25px;
}

.w-30 {
  width: 30px;
}

.h-30 {
  height: 30px;
}

.w-32 {
  width: 32px;
}

.h-32, .modal__body .material-icons {
  height: 32px;
}

.w-35 {
  width: 35px;
}

.h-35 {
  height: 35px;
}

.w-36, .modal__body .material-icons {
  width: 36px;
}

.h-36 {
  height: 36px;
}

.w-40, .sidebar__user__avatar {
  width: 40px;
}

.h-40, .sidebar__user__avatar {
  height: 40px;
}

.w-45 {
  width: 45px;
}

.h-45 {
  height: 45px;
}

.w-50 {
  width: 50px;
}

.h-50 {
  height: 50px;
}

.w-55 {
  width: 55px;
}

.h-55 {
  height: 55px;
}

.w-60 {
  width: 60px;
}

.h-60 {
  height: 60px;
}

.w-65 {
  width: 65px;
}

.h-65 {
  height: 65px;
}

.w-70 {
  width: 70px;
}

.h-70 {
  height: 70px;
}

.w-75 {
  width: 75px;
}

.h-75 {
  height: 75px;
}

.w-80 {
  width: 80px;
}

.h-80 {
  height: 80px;
}

.w-85 {
  width: 85px;
}

.h-85 {
  height: 85px;
}

.w-90 {
  width: 90px;
}

.h-90 {
  height: 90px;
}

.w-95, .sidebar__brand img, .login__intro__brand img {
  width: 95px;
}

.h-95 {
  height: 95px;
}

.w-100, .rule__detail, .rule__header, .prequalifications-button-bottom .mat-stroked-button, .prequalifications-button-top .mat-stroked-button, .sidebar__logout button.mat-stroked-button, .sidebar .nav-list, .main-content {
  width: 100px;
}

.h-100, .welcome-content {
  height: 100px;
}

.w-auto, .rule__header__content, .prequalifications-search .mat-form-field {
  width: auto;
}

.h-auto, .sidebar__brand img, .login__intro__brand img {
  height: auto;
}

.p-0, .prequalifications-search .mat-form-field .mat-form-field-wrapper {
  padding: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0, .rule:last-child {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.px-0, .card.main-card .detail {
  padding-right: 0px;
  padding-left: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.m-0, .prequalifications-search .mat-form-field .mat-form-field-wrapper, .sidebar__menu {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  margin-right: 0px;
}

.mb-0, .prequalifications-search button.mat-flat-button, .sidebar__menu__section, .modal__body .buttons button, .card.main-card .summary-wrapper .title:first-child, .d-inline-flex button.mat-flat-button,
.d-inline-flex button.mat-stroked-button,
.d-inline-flex a.mat-flat-button,
.d-inline-flex a.mat-stroked-button {
  margin-bottom: 0px;
}

.ml-0, .rule__detail {
  margin-left: 0px;
}

.mx-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.py-5, .modal__body button, .google-btn p {
  padding-top: 5px;
  padding-bottom: 5px;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5, .rule__header__code {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5, .rule__header__content__periods vv-icon {
  margin-left: 5px;
}

.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.my-5, .modal__body .file-tittle-loading,
.modal__body .file-tittle-success,
.modal__body .file-tittle-error,
.modal__body .file-tittle-delete,
.modal__body .title {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.px-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.py-8, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  padding-top: 8px;
  padding-bottom: 8px;
}

.m-8 {
  margin: 8px;
}

.mt-8, .card.main-card .summary-wrapper .subtitle {
  margin-top: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8, .login__form .subtitle, .login__intro__info .title {
  margin-bottom: 8px;
}

.ml-8, .prequalifications-search button.mat-flat-button {
  margin-left: 8px;
}

.mx-8 {
  margin-right: 8px;
  margin-left: 8px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.m-10 {
  margin: 10px;
}

.mt-10, .rule__detail ul li, .card .table-wrapper {
  margin-top: 10px;
}

.mr-10, .google-btn .google-icon-wrapper {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10, .sidebar__user__info, .sidebar__menu__item__text {
  margin-left: 10px;
}

.mx-10, .modal__body .buttons button {
  margin-right: 10px;
  margin-left: 10px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.p-12 {
  padding: 12px;
}

.pt-12, .container {
  padding-top: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.px-12 {
  padding-right: 12px;
  padding-left: 12px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.m-12 {
  margin: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mx-12 {
  margin-right: 12px;
  margin-left: 12px;
}

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.p-15, .modal__content, .google-btn {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15, .user-form .credit-info__monthly-fee,
.application-form .credit-info__monthly-fee {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.px-16, .table-wrapper table th,
.table-wrapper table td,
.table-wrapper table.mat-table th,
.table-wrapper table.mat-table td, .login__form, .main-content, .card {
  padding-right: 16px;
  padding-left: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.m-16 {
  margin: 16px;
}

.mt-16, .prequalifications-button-bottom .mat-stroked-button, .list__item {
  margin-top: 16px;
}

.mr-16, .d-inline-flex button.mat-flat-button,
.d-inline-flex button.mat-stroked-button,
.d-inline-flex a.mat-flat-button,
.d-inline-flex a.mat-stroked-button {
  margin-right: 16px;
}

.mb-16, .prequalifications-button-top .mat-stroked-button, .row p, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mx-16, .login__intro__info, .modal--sm .modal__content {
  margin-right: 16px;
  margin-left: 16px;
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.p-18 {
  padding: 18px;
}

.pt-18 {
  padding-top: 18px;
}

.pr-18 {
  padding-right: 18px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pl-18 {
  padding-left: 18px;
}

.px-18 {
  padding-right: 18px;
  padding-left: 18px;
}

.py-18, .sidebar__brand, .login__intro__brand {
  padding-top: 18px;
  padding-bottom: 18px;
}

.m-18 {
  margin: 18px;
}

.mt-18 {
  margin-top: 18px;
}

.mr-18 {
  margin-right: 18px;
}

.mb-18 {
  margin-bottom: 18px;
}

.ml-18 {
  margin-left: 18px;
}

.mx-18 {
  margin-right: 18px;
  margin-left: 18px;
}

.my-18 {
  margin-top: 18px;
  margin-bottom: 18px;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.py-20, .table-wrapper table th,
.table-wrapper table td,
.table-wrapper table.mat-table th,
.table-wrapper table.mat-table td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20, .card {
  margin-bottom: 20px;
}

.ml-20, .rule__detail ul {
  margin-left: 20px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.my-20, .login__intro__img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.px-25, .sidebar__brand, .login__intro__brand {
  padding-right: 25px;
  padding-left: 25px;
}

.py-25, .rule, .sidebar__logout button.mat-stroked-button, .sidebar__user, .sidebar__submenu__item, .sidebar__menu__item {
  padding-top: 25px;
  padding-bottom: 25px;
}

.m-25 {
  margin: 25px;
}

.mt-25, .modal__body button {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25, .container .subtitle, .card.main-card .summary-wrapper .subtitle {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30, .card__header {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.p-32 {
  padding: 32px;
}

.pt-32, .login__form {
  padding-top: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.px-32, .modal__body button, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  padding-right: 32px;
  padding-left: 32px;
}

.py-32, .main-content, .card {
  padding-top: 32px;
  padding-bottom: 32px;
}

.m-32 {
  margin: 32px;
}

.mt-32, .login__intro__info {
  margin-top: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-32, .prequalifications-search, .login__form .google-btn, .login__form .title {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mx-32 {
  margin-right: 32px;
  margin-left: 32px;
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.p-35 {
  padding: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40, .modal__body {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.p-45 {
  padding: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.px-45, .sidebar__user, .sidebar__submenu__item, .sidebar__menu__item {
  padding-right: 45px;
  padding-left: 45px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45, .login__intro__info {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.p-50 {
  padding: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.m-50 {
  margin: 50px;
}

.mt-50, .modal__body .file-loading,
.modal__body .loading {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.p-55 {
  padding: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.px-55 {
  padding-right: 55px;
  padding-left: 55px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mx-55 {
  margin-right: 55px;
  margin-left: 55px;
}

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.p-60 {
  padding: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.px-60 {
  padding-right: 60px;
  padding-left: 60px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mx-60 {
  margin-right: 60px;
  margin-left: 60px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.p-65 {
  padding: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.px-65 {
  padding-right: 65px;
  padding-left: 65px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mx-65 {
  margin-right: 65px;
  margin-left: 65px;
}

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.p-72 {
  padding: 72px;
}

.pt-72 {
  padding-top: 72px;
}

.pr-72 {
  padding-right: 72px;
}

.pb-72, .login__form {
  padding-bottom: 72px;
}

.pl-72 {
  padding-left: 72px;
}

.px-72 {
  padding-right: 72px;
  padding-left: 72px;
}

.py-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.m-72 {
  margin: 72px;
}

.mt-72 {
  margin-top: 72px;
}

.mr-72 {
  margin-right: 72px;
}

.mb-72 {
  margin-bottom: 72px;
}

.ml-72 {
  margin-left: 72px;
}

.mx-72 {
  margin-right: 72px;
  margin-left: 72px;
}

.my-72 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.p-95 {
  padding: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.px-95 {
  padding-right: 95px;
  padding-left: 95px;
}

.py-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mx-95 {
  margin-right: 95px;
  margin-left: 95px;
}

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.p-100 {
  padding: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.px-100 {
  padding-right: 100px;
  padding-left: 100px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mx-100 {
  margin-right: 100px;
  margin-left: 100px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.p-auto {
  padding: auto;
}

.pt-auto {
  padding-top: auto;
}

.pr-auto {
  padding-right: auto;
}

.pb-auto {
  padding-bottom: auto;
}

.pl-auto {
  padding-left: auto;
}

.px-auto, .sidebar__logout button.mat-stroked-button {
  padding-right: auto;
  padding-left: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto, .login__intro__img {
  margin-right: auto;
  margin-left: auto;
}

.my-auto, .google-btn p, .google-btn .google-icon-wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.text-center, .login__intro__img, .modal__body, .google-btn {
  text-align: center;
}

.text-left, .table-wrapper table th,
.table-wrapper table td,
.table-wrapper table.mat-table th,
.table-wrapper table.mat-table td, .table-wrapper table tr.mat-no-data-row td,
.table-wrapper table.mat-table tr.mat-no-data-row td, .card.main-card .summary-wrapper .title {
  text-align: left;
}

.text-right, .rule__header__content__periods {
  text-align: right;
}

.text-primary, .user-form .credit-info__monthly-fee,
.application-form .credit-info__monthly-fee, .simulation-card .simulator__list__item__icon, .container .subtitle, .sidebar__user__avatar__text {
  color: #007b80;
}

.text-primary-0, .login__intro__info {
  color: #b7ffa0;
}

.text-primary-100 {
  color: #7fe55f;
}

.text-primary-200 {
  color: #00ad86;
}

.text-primary-300, .login__form .title, a {
  color: #007b80;
}

.text-primary-400, .sidebar__menu__item:hover, .sidebar__menu__item.active, a.return-normal {
  color: #003c49;
}

.text-primary-500 {
  color: #072f37;
}

.text-primary-600 {
  color: #000000;
}

.text-secondary, .table-wrapper table th,
.table-wrapper table.mat-table th, body, .modal__body .file-loading,
.modal__body .file-success,
.modal__body .file-delete,
.modal__body .success {
  color: #003c49;
}

.text-secondary-0 {
  color: #ecedee;
}

.text-secondary-100, .rule__header__content__periods {
  color: #c5c8cb;
}

.text-secondary-200 {
  color: #9fa4a9;
}

.text-secondary-300, .rule__header__content__description, .table-wrapper table td,
.table-wrapper table.mat-table td {
  color: #788087;
}

.text-secondary-400 {
  color: #565b60;
}

.text-secondary-500 {
  color: #33373a;
}

.text-secondary-600 {
  color: #111213;
}

.text-success {
  color: #16d090;
}

.text-success-0 {
  color: #eaf9f2;
}

.text-error, .modal__body .file-error,
.modal__body .error {
  color: #e74f48;
}

.text-warning {
  color: #ffaa3f;
}

.text-info {
  color: #ead627;
}

.text-white, .rule__header__code, .sidebar, .login__intro__info p, .list__item__decorator, .modal__body .btn {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-uppercase, .rule__header__content__periods, .modal__body button, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-style-italic {
  font-style: italic;
}

.text-light, .user-form .credit-info__monthly-fee,
.application-form .credit-info__monthly-fee, .login__intro__info .title, .vivela-contact-bar__occupation, .heading-3 {
  font-weight: 300;
}

.text-regular, .table-wrapper table td,
.table-wrapper table.mat-table td, .sidebar__user__info, .sidebar__submenu__item, .sidebar__menu__item__text, .login__form .subtitle, .login__intro__info p, .row p, .list__item, .modal__body .detail,
.modal__body .message, .card.main-card, .vivela-tracking-step__description, .vivela-avatar__text, .bottom-navigation-item__text, .google-btn p, .body-4, .body-3, .body-2, a, .body-1, .heading-6, .heading-5, .heading-4, .heading-2, .heading-1 {
  font-weight: 400;
}

.text-medium, a.return-normal, .modal__body button, .vivela-tracking-step__title, .vivela-contact-bar__name, .vivela-property-features__text, .vivela-bottom-sheet__title, VivelaListItem Label, .vivela-step Label, .text-field {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-bold, .rule__header__content__periods, .container .subtitle, .table-wrapper table th,
.table-wrapper table.mat-table th, .sidebar__user__avatar__text, .sidebar__submenu__item:hover, .sidebar__submenu__item.active, .sidebar__menu__item:hover .sidebar__menu__item__text, .sidebar__menu__item.active .sidebar__menu__item__text, .login__form .subtitle, .login__form .title, .modal__body .file-tittle-loading,
.modal__body .file-tittle-success,
.modal__body .file-tittle-error,
.modal__body .file-tittle-delete,
.modal__body .title, .card__header, .button__text, .google-btn p {
  font-weight: 700;
}

.text-extrabold, .rule__header__code {
  font-weight: 800;
}

.tracking-tightest {
  letter-spacing: -0.078em;
}

.tracking-tighter {
  letter-spacing: -0.03em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0em;
}

.tracking-wide, .simulation-subtitle, .login__intro__info p, .login__intro__info .title, .body-4 {
  letter-spacing: 0.03em;
}

.tracking-wider, .modal__body button {
  letter-spacing: 0.078em;
}

.tracking-widest, button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  letter-spacing: 0.125em;
}

.heading-1 {
  font-size: 70px;
}

.heading-2 {
  font-size: 54px;
}

.body-4 {
  font-size: 10px;
}

.badge {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;
}

.badge__circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.badge-primary {
  color: #ffffff;
}
.badge-primary .badge__circle {
  background: #007b80;
}

.badge-outline-primary {
  color: #007b80;
}
.badge-outline-primary .badge__circle {
  background: #007b80;
}

.badge-primary-0 {
  color: #ffffff;
}
.badge-primary-0 .badge__circle {
  background: #b7ffa0;
}

.badge-outline-primary-0 {
  color: #b7ffa0;
}
.badge-outline-primary-0 .badge__circle {
  background: #b7ffa0;
}

.badge-primary-100 {
  color: #ffffff;
}
.badge-primary-100 .badge__circle {
  background: #7fe55f;
}

.badge-outline-primary-100 {
  color: #7fe55f;
}
.badge-outline-primary-100 .badge__circle {
  background: #7fe55f;
}

.badge-primary-200 {
  color: #ffffff;
}
.badge-primary-200 .badge__circle {
  background: #00ad86;
}

.badge-outline-primary-200 {
  color: #00ad86;
}
.badge-outline-primary-200 .badge__circle {
  background: #00ad86;
}

.badge-primary-300 {
  color: #ffffff;
}
.badge-primary-300 .badge__circle {
  background: #007b80;
}

.badge-outline-primary-300 {
  color: #007b80;
}
.badge-outline-primary-300 .badge__circle {
  background: #007b80;
}

.badge-primary-400 {
  color: #ffffff;
}
.badge-primary-400 .badge__circle {
  background: #003c49;
}

.badge-outline-primary-400 {
  color: #003c49;
}
.badge-outline-primary-400 .badge__circle {
  background: #003c49;
}

.badge-primary-500 {
  color: #ffffff;
}
.badge-primary-500 .badge__circle {
  background: #072f37;
}

.badge-outline-primary-500 {
  color: #072f37;
}
.badge-outline-primary-500 .badge__circle {
  background: #072f37;
}

.badge-primary-600 {
  color: #ffffff;
}
.badge-primary-600 .badge__circle {
  background: #000000;
}

.badge-outline-primary-600 {
  color: #000000;
}
.badge-outline-primary-600 .badge__circle {
  background: #000000;
}

.badge-secondary {
  color: #ffffff;
}
.badge-secondary .badge__circle {
  background: #003c49;
}

.badge-outline-secondary {
  color: #003c49;
}
.badge-outline-secondary .badge__circle {
  background: #003c49;
}

.badge-secondary-0 {
  color: #ffffff;
}
.badge-secondary-0 .badge__circle {
  background: #ecedee;
}

.badge-outline-secondary-0 {
  color: #ecedee;
}
.badge-outline-secondary-0 .badge__circle {
  background: #ecedee;
}

.badge-secondary-100 {
  color: #ffffff;
}
.badge-secondary-100 .badge__circle {
  background: #c5c8cb;
}

.badge-outline-secondary-100 {
  color: #c5c8cb;
}
.badge-outline-secondary-100 .badge__circle {
  background: #c5c8cb;
}

.badge-secondary-200 {
  color: #ffffff;
}
.badge-secondary-200 .badge__circle {
  background: #9fa4a9;
}

.badge-outline-secondary-200 {
  color: #9fa4a9;
}
.badge-outline-secondary-200 .badge__circle {
  background: #9fa4a9;
}

.badge-secondary-300 {
  color: #ffffff;
}
.badge-secondary-300 .badge__circle {
  background: #788087;
}

.badge-outline-secondary-300 {
  color: #788087;
}
.badge-outline-secondary-300 .badge__circle {
  background: #788087;
}

.badge-secondary-400 {
  color: #ffffff;
}
.badge-secondary-400 .badge__circle {
  background: #565b60;
}

.badge-outline-secondary-400 {
  color: #565b60;
}
.badge-outline-secondary-400 .badge__circle {
  background: #565b60;
}

.badge-secondary-500 {
  color: #ffffff;
}
.badge-secondary-500 .badge__circle {
  background: #33373a;
}

.badge-outline-secondary-500 {
  color: #33373a;
}
.badge-outline-secondary-500 .badge__circle {
  background: #33373a;
}

.badge-secondary-600 {
  color: #ffffff;
}
.badge-secondary-600 .badge__circle {
  background: #111213;
}

.badge-outline-secondary-600 {
  color: #111213;
}
.badge-outline-secondary-600 .badge__circle {
  background: #111213;
}

.badge-success {
  color: #ffffff;
}
.badge-success .badge__circle {
  background: #16d090;
}

.badge-outline-success {
  color: #16d090;
}
.badge-outline-success .badge__circle {
  background: #16d090;
}

.badge-success-0 {
  color: #ffffff;
}
.badge-success-0 .badge__circle {
  background: #eaf9f2;
}

.badge-outline-success-0 {
  color: #eaf9f2;
}
.badge-outline-success-0 .badge__circle {
  background: #eaf9f2;
}

.badge-error {
  color: #ffffff;
}
.badge-error .badge__circle {
  background: #e74f48;
}

.badge-outline-error {
  color: #e74f48;
}
.badge-outline-error .badge__circle {
  background: #e74f48;
}

.badge-warning {
  color: #ffffff;
}
.badge-warning .badge__circle {
  background: #ffaa3f;
}

.badge-outline-warning {
  color: #ffaa3f;
}
.badge-outline-warning .badge__circle {
  background: #ffaa3f;
}

.badge-info {
  color: #ffffff;
}
.badge-info .badge__circle {
  background: #ead627;
}

.badge-outline-info {
  color: #ead627;
}
.badge-outline-info .badge__circle {
  background: #ead627;
}

.badge-white {
  color: #ffffff;
}
.badge-white .badge__circle {
  background: #ffffff;
}

.badge-outline-white {
  color: #ffffff;
}
.badge-outline-white .badge__circle {
  background: #ffffff;
}

.badge-black {
  color: #ffffff;
}
.badge-black .badge__circle {
  background: #000000;
}

.badge-outline-black {
  color: #000000;
}
.badge-outline-black .badge__circle {
  background: #000000;
}

.badge--small {
  font-size: 12px;
  line-height: 16px;
  min-width: 100px;
}
.badge--small .badge__circle {
  height: 7px;
  width: 7px;
}

.btn {
  font-weight: bold;
  font-size: 14px;
  padding: 0px 24px;
  text-align: center;
  vertical-align: middle;
  height: 44px;
  border-radius: 50px;
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover, .btn:active, .btn:focus {
  outline: none;
}
.btn-icon {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  min-width: 0px;
  text-align: center;
  padding: 0;
}

.btn-primary {
  background-color: #007b80;
  color: #ffffff;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #00565a;
  color: #ffffff;
}
.btn-primary.disable {
  background-color: rgba(0, 123, 128, 0.6);
}

.btn-primary-0 {
  background-color: #b7ffa0;
  color: #003c49;
}
.btn-primary-0:hover, .btn-primary-0:active, .btn-primary-0:focus {
  background-color: #9aff7a;
  color: #003c49;
}
.btn-primary-0.disable {
  background-color: rgba(183, 255, 160, 0.6);
}

.btn-primary-100 {
  background-color: #7fe55f;
  color: #003c49;
}
.btn-primary-100:hover, .btn-primary-100:active, .btn-primary-100:focus {
  background-color: #65e03e;
  color: #003c49;
}
.btn-primary-100.disable {
  background-color: rgba(127, 229, 95, 0.6);
}

.btn-primary-200 {
  background-color: #00ad86;
  color: #ffffff;
}
.btn-primary-200:hover, .btn-primary-200:active, .btn-primary-200:focus {
  background-color: #008768;
  color: #ffffff;
}
.btn-primary-200.disable {
  background-color: rgba(0, 173, 134, 0.6);
}

.btn-primary-300 {
  background-color: #007b80;
  color: #ffffff;
}
.btn-primary-300:hover, .btn-primary-300:active, .btn-primary-300:focus {
  background-color: #00565a;
  color: #ffffff;
}
.btn-primary-300.disable {
  background-color: rgba(0, 123, 128, 0.6);
}

.btn-primary-400 {
  background-color: #003c49;
  color: #ffffff;
}
.btn-primary-400:hover, .btn-primary-400:active, .btn-primary-400:focus {
  background-color: #001d23;
  color: #ffffff;
}
.btn-primary-400.disable {
  background-color: rgba(0, 60, 73, 0.6);
}

.btn-primary-500 {
  background-color: #072f37;
  color: #ffffff;
}
.btn-primary-500:hover, .btn-primary-500:active, .btn-primary-500:focus {
  background-color: #031215;
  color: #ffffff;
}
.btn-primary-500.disable {
  background-color: rgba(7, 47, 55, 0.6);
}

.btn-primary-600 {
  background-color: #000000;
  color: #ffffff;
}
.btn-primary-600:hover, .btn-primary-600:active, .btn-primary-600:focus {
  background-color: black;
  color: #ffffff;
}
.btn-primary-600.disable {
  background-color: rgba(0, 0, 0, 0.6);
}

.btn-secondary {
  background-color: #003c49;
  color: #ffffff;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  background-color: #001d23;
  color: #ffffff;
}
.btn-secondary.disable {
  background-color: rgba(0, 60, 73, 0.6);
}

.btn-secondary-0 {
  background-color: #ecedee;
  color: #003c49;
}
.btn-secondary-0:hover, .btn-secondary-0:active, .btn-secondary-0:focus {
  background-color: #d8dadc;
  color: #003c49;
}
.btn-secondary-0.disable {
  background-color: rgba(236, 237, 238, 0.6);
}

.btn-secondary-100 {
  background-color: #c5c8cb;
  color: #003c49;
}
.btn-secondary-100:hover, .btn-secondary-100:active, .btn-secondary-100:focus {
  background-color: #b1b5b9;
  color: #003c49;
}
.btn-secondary-100.disable {
  background-color: rgba(197, 200, 203, 0.6);
}

.btn-secondary-200 {
  background-color: #9fa4a9;
  color: #003c49;
}
.btn-secondary-200:hover, .btn-secondary-200:active, .btn-secondary-200:focus {
  background-color: #8b9197;
  color: #003c49;
}
.btn-secondary-200.disable {
  background-color: rgba(159, 164, 169, 0.6);
}

.btn-secondary-300 {
  background-color: #788087;
  color: #ffffff;
}
.btn-secondary-300:hover, .btn-secondary-300:active, .btn-secondary-300:focus {
  background-color: #666d73;
  color: #ffffff;
}
.btn-secondary-300.disable {
  background-color: rgba(120, 128, 135, 0.6);
}

.btn-secondary-400 {
  background-color: #565b60;
  color: #ffffff;
}
.btn-secondary-400:hover, .btn-secondary-400:active, .btn-secondary-400:focus {
  background-color: #44484c;
  color: #ffffff;
}
.btn-secondary-400.disable {
  background-color: rgba(86, 91, 96, 0.6);
}

.btn-secondary-500 {
  background-color: #33373a;
  color: #ffffff;
}
.btn-secondary-500:hover, .btn-secondary-500:active, .btn-secondary-500:focus {
  background-color: #212426;
  color: #ffffff;
}
.btn-secondary-500.disable {
  background-color: rgba(51, 55, 58, 0.6);
}

.btn-secondary-600 {
  background-color: #111213;
  color: #ffffff;
}
.btn-secondary-600:hover, .btn-secondary-600:active, .btn-secondary-600:focus {
  background-color: black;
  color: #ffffff;
}
.btn-secondary-600.disable {
  background-color: rgba(17, 18, 19, 0.6);
}

.btn-success {
  background-color: #16d090;
  color: #ffffff;
}
.btn-success:hover, .btn-success:active, .btn-success:focus {
  background-color: #12ad78;
  color: #ffffff;
}
.btn-success.disable {
  background-color: rgba(22, 208, 144, 0.6);
}

.btn-success-0 {
  background-color: #eaf9f2;
  color: #003c49;
}
.btn-success-0:hover, .btn-success-0:active, .btn-success-0:focus {
  background-color: #ccf1e0;
  color: #003c49;
}
.btn-success-0.disable {
  background-color: rgba(234, 249, 242, 0.6);
}

.btn-error {
  background-color: #e74f48;
  color: #ffffff;
}
.btn-error:hover, .btn-error:active, .btn-error:focus {
  background-color: #e32e26;
  color: #ffffff;
}
.btn-error.disable {
  background-color: rgba(231, 79, 72, 0.6);
}

.btn-warning {
  background-color: #ffaa3f;
  color: #003c49;
}
.btn-warning:hover, .btn-warning:active, .btn-warning:focus {
  background-color: #ff9919;
  color: #003c49;
}
.btn-warning.disable {
  background-color: rgba(255, 170, 63, 0.6);
}

.btn-info {
  background-color: #ead627;
  color: #003c49;
}
.btn-info:hover, .btn-info:active, .btn-info:focus {
  background-color: #d6c215;
  color: #003c49;
}
.btn-info.disable {
  background-color: rgba(234, 214, 39, 0.6);
}

.btn-white {
  background-color: #ffffff;
  color: #003c49;
}
.btn-white:hover, .btn-white:active, .btn-white:focus {
  background-color: #ececec;
  color: #003c49;
}
.btn-white.disable {
  background-color: rgba(255, 255, 255, 0.6);
}

.btn-black {
  background-color: #000000;
  color: #ffffff;
}
.btn-black:hover, .btn-black:active, .btn-black:focus {
  background-color: black;
  color: #ffffff;
}
.btn-black.disable {
  background-color: rgba(0, 0, 0, 0.6);
}

.btn-link {
  background-color: transparent;
  color: #007b80;
}

.google-btn {
  width: max-content;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084), 0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
}
.google-btn .google-icon-wrapper {
  width: 25px;
  height: 25px;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #007b80;
}

button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  width: 100%;
  white-space: normal;
}

.d-inline-flex button.mat-flat-button:last-child,
.d-inline-flex button.mat-stroked-button:last-child,
.d-inline-flex a.mat-flat-button:last-child,
.d-inline-flex a.mat-stroked-button:last-child {
  margin-right: 0;
}

@media (min-width: 768px) {
  button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
    margin-right: 16px;
    margin-bottom: 25px;
    width: max-content;
  }
  button.mat-flat-button:last-child,
button.mat-stroked-button:last-child,
a.mat-flat-button:last-child,
a.mat-stroked-button:last-child {
    margin-right: 0;
  }
}
.button {
  height: 50;
  width: 220;
  border-radius: 25;
}
.button__text {
  color: #505050;
  font-size: 14;
  text-transform: uppercase;
}

.button--primary {
  background-color: #007b80;
}
.button--primary .button__text {
  color: #ffffff;
}

.button--secondary {
  background-color: #003c49;
}
.button--secondary .button__text {
  color: #ffffff;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: blink;
  cursor: pointer;
}
.button-link:hover {
  text-decoration: underline;
}

.icon-button {
  padding: 6;
  border-radius: 50;
}
.icon-button Label {
  color: #505050;
}

.icon-button--primary {
  background-color: #007b80;
  border-color: #007b80;
}
.icon-button--primary Label {
  color: #ffffff;
}

.icon-button--secondary {
  background-color: #003c49;
  border-color: #003c49;
}
.icon-button--secondary Label {
  color: #ffffff;
}

.icon-button--outlined {
  background-color: #ffffff;
  border-width: 2;
}
.icon-button--outlined.icon-button--primary Label {
  color: #007b80;
}
.icon-button--outlined.icon-button--secondary Label {
  color: #003c49;
}

.text-field {
  width: 100%;
  height: 63;
}
.text-field__border {
  border-color: #007b80;
  height: 44;
  width: 100%;
  top: 7;
  border-width: 1;
  border-radius: 8;
}
.text-field__label {
  background-color: #ffffff;
  color: #505050;
  font-size: 14;
  line-height: 14;
  top: 20;
  left: 20;
}
.text-field__leading-icon {
  color: #505050;
  margin-right: 8;
}
.text-field__trailing-icon {
  color: #505050;
  margin-left: 8;
}
.text-field__input-container {
  width: 100%;
  top: 19;
  padding: 0 20;
}
.text-field__input {
  color: #505050;
  background-color: transparent;
  border-bottom-color: transparent;
  font-size: 14;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom-width: 1;
}
.text-field__helper-text {
  color: #505050;
  font-size: 10;
  line-height: 10;
  top: 51;
  left: 20;
}

.text-field--active .text-field__border {
  border-color: #003c49;
  border-width: 2;
}
.text-field--active .text-field__label {
  color: #003c49;
}
.text-field--active .text-field__helper-text {
  color: #003c49;
}

.textview {
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  font-size: 20px;
}
.textview__label {
  margin-right: 5px;
}

.icon {
  text-transform: none;
  font-size: 24px;
}
.icon--xs {
  font-size: 10px;
}
.icon--xsm {
  font-size: 14px;
}
.icon--sm, .list__item__decorator .icon {
  font-size: 18px;
}
.icon--md {
  font-size: 36px;
}
.icon--lg {
  font-size: 48px;
}

.select__btn {
  background-color: #ffffff;
  padding: 14px 20px;
  color: #9e9fa1;
  border-width: 1px;
  border-color: #656566;
  border-radius: 8px;
  font-size: 14px;
  vertical-align: center;
}
.select__btn__icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #ededed;
  text-align: center;
}
.select__btn:focus-visible {
  outline-color: #656566;
}
.select__btn.invalid:not(:focus-visible) {
  border: solid 2px #e74f48;
}
.select__options {
  margin-top: 7px;
}
.select__options--border {
  background-color: white;
  border-radius: 8px;
  border-width: 1;
  border-color: #e7e7e7;
}
.select__options.collapsed {
  visibility: collapsed;
}
.select__options .option {
  color: #4e5057;
  height: 50px;
  padding: 15px;
  border: none;
}
.select__options .option.active {
  background: #e7e7e7;
}
.select__options .separator {
  border-bottom-width: 1px;
  border-bottom-color: #f2f7ff;
}

.vivela-stepper-container {
  padding: 16 24;
}

.vivela-stepper-line {
  height: 1;
  background-color: #007b80;
  vertical-align: center;
}

.vivela-step {
  width: 40;
  height: 40;
  border-radius: 24;
  text-align: center;
  vertical-align: middle;
  border-width: 1;
  border-color: #007b80;
  background-color: #ffffff;
}
.vivela-step.--active {
  border-color: #003c49;
}
.vivela-step.--active Label {
  color: #003c49;
}
.vivela-step.--completed {
  border-color: #003c49;
  background-color: #003c49;
}
.vivela-step.--completed Label {
  color: #ffffff;
}
.vivela-step Label {
  font-size: 16;
  color: #505050;
}

VivelaDivider {
  height: 1;
  background-color: #ecedee;
}

VivelaListItem {
  padding: 16 8;
}
VivelaListItem VivelaIcon {
  margin-right: 8;
}
VivelaListItem Label {
  font-size: 14;
}

.vivela-check-box {
  margin-right: 16;
}
.vivela-check-box VivelaIcon {
  color: #505050;
}

.vivela-bottom-sheet {
  padding: 20;
  background: #ffffff;
  border-radius: 20 20 0 0;
  height: 250;
}
.vivela-bottom-sheet__title {
  color: #505050;
  font-size: 12;
  margin-left: 8;
}

.vivela-switch__track {
  border-radius: 30;
  width: 100%;
}
.vivela-switch__track--ios {
  top: 0;
  left: 0;
  height: 30;
  background: #dddddd;
}
.vivela-switch__track--android {
  top: 6;
  left: 0;
  height: 18;
  background: #dddddd;
}
.vivela-switch__track--active {
  background: #003c49;
}
.vivela-switch__thumb {
  width: 100%;
  height: 100%;
  border-radius: 30;
  background: #ffffff;
  android-elevation: 5;
}

.bottom-navigation-bar {
  padding: 10 20;
  width: 100%;
  height: 60;
  border-top-left-radius: 15;
  border-top-right-radius: 15;
  background-color: #ffffff;
}

.bottom-navigation-item {
  width: 60;
  height: 40;
  color: #505050;
}
.bottom-navigation-item__icon {
  margin: 0;
}
.bottom-navigation-item__text {
  font-size: 12;
}

.vivela-avatar {
  background: #ffffff;
  color: #505050;
  height: 40;
  width: 40;
  border-radius: 20;
}
.vivela-avatar__image {
  border-radius: 20;
}
.vivela-avatar__icon {
  margin: 0;
}
.vivela-avatar__text {
  font-size: 14;
}

.vivela-property-features--horizontal .vivela-property-features__item {
  margin-right: 20;
}
.vivela-property-features--vertical {
  flex-direction: column;
}
.vivela-property-features--vertical .vivela-property-features__item {
  margin-bottom: 10;
}
.vivela-property-features__item {
  flex-direction: column;
}
.vivela-property-features__avatar {
  justify-content: center;
}
.vivela-property-features__text {
  color: #ffffff;
  font-size: 10;
  text-align: center;
}

.vv-radio-button {
  display: flex;
  align-items: center;
}
.vv-radio-button .mat-error {
  padding: 0 2em;
  font-size: 75%;
}
.vv-radio-button .subtitle {
  font-size: 14px;
  padding: 0 15px;
  margin-bottom: 17px;
  margin-top: 10px;
  width: 100%;
}

.vv-radio-button-label {
  margin-bottom: 20px;
}

.mat-radio-group {
  display: flex;
  align-items: center;
  height: 56px;
  flex: 1;
  justify-content: space-around;
}

.mat-radio-button {
  flex: 1;
  padding: 0 15px;
}

.radio-button-invalid .mat-radio-outer-circle {
  border-color: #e74f48;
}

.vivela-action-bar {
  width: 100%;
  height: 40;
  padding: 0 20;
}

.vivela-contact-bar {
  padding: 0 20;
  width: 100%;
  height: 75;
  border-top-left-radius: 15;
  border-top-right-radius: 15;
  background-color: #dddddd;
}
.vivela-contact-bar__avatar {
  margin-right: 10;
}
.vivela-contact-bar__name {
  font-size: 16;
  color: rgba(80, 80, 80, 0.8);
}
.vivela-contact-bar__occupation {
  font-size: 14;
  color: rgba(80, 80, 80, 0.8);
}

.vivela-favorite-button {
  padding: 6;
  border-radius: 15;
}

.vivela-favorite-button--primary {
  background: #ffffff;
}
.vivela-favorite-button--primary Label {
  color: #007b80;
}
.vivela-favorite-button--primary.vivela-favorite-button--active {
  background: #ffffff;
}
.vivela-favorite-button--primary.vivela-favorite-button--active Label {
  color: #007b80;
}

.vivela-favorite-button--secondary {
  background: #ffffff;
}
.vivela-favorite-button--secondary Label {
  color: #003c49;
}
.vivela-favorite-button--secondary.vivela-favorite-button--active {
  background: #003c49;
}
.vivela-favorite-button--secondary.vivela-favorite-button--active Label {
  color: #ffffff;
}

.vivela-reel__property-features {
  margin-right: 20;
}
.vivela-reel__information {
  margin-left: 20;
  margin-bottom: 40;
}
.vivela-reel__price {
  color: #ffffff;
  font-size: 25;
}
.vivela-reel__address {
  color: #ffffff;
  font-size: 15;
}

.vivela-tracking-stepper VivelaTrackingStep {
  margin-bottom: 20;
}

.vivela-tracking-step__content {
  background-color: #ffffff;
  padding: 0 20;
  border-top-right-radius: 15;
  border-bottom-right-radius: 15;
}
.vivela-tracking-step__title {
  font-size: 14;
  color: #505050;
}
.vivela-tracking-step__description {
  font-size: 12;
  color: #505050;
  text-wrap: true;
}
.vivela-tracking-step__circle {
  background-color: #dddddd;
  border-radius: 10;
  height: 20;
  width: 20;
}
.vivela-tracking-step__circle VivelaIcon {
  color: #ffffff;
}
.vivela-tracking-step__line {
  background-color: #dddddd;
  border-bottom-right-radius: 2;
  border-bottom-left-radius: 2;
  width: 4;
}
.vivela-tracking-step__ellipsis {
  width: 2;
}
.vivela-tracking-step__ellipsis Label {
  background-color: #dddddd;
  border-bottom-right-radius: 1;
  border-bottom-left-radius: 1;
  width: 2;
  height: 2;
  margin-top: 8;
}

.vivela-tracking-step--error .vivela-tracking-step__content {
  background-color: #ffefef;
  padding: 20 20;
}
.vivela-tracking-step--error .vivela-tracking-step__circle {
  background-color: #e74f48;
}
.vivela-tracking-step--error .vivela-tracking-step__circle VivelaIcon {
  color: #ffffff;
}
.vivela-tracking-step--error .vivela-tracking-step__line {
  background-color: #e74f48;
}

.vivela-tracking-step--success .vivela-tracking-step__content {
  padding: 0 20;
}
.vivela-tracking-step--success .vivela-tracking-step__circle {
  background-color: #16d090;
}
.vivela-tracking-step--success .vivela-tracking-step__circle VivelaIcon {
  color: #ffffff;
}
.vivela-tracking-step--success .vivela-tracking-step__line {
  background-color: #16d090;
}
.vivela-tracking-step--success .vivela-tracking-step__ellipsis Label {
  background-color: #16d090;
}

.vivela-tracking-step--current .vivela-tracking-step__content {
  background-color: #fff3ee;
  padding: 20 20;
}
.vivela-tracking-step--current .vivela-tracking-step__title {
  color: #003c49;
}
.vivela-tracking-step--current .vivela-tracking-step__circle {
  background-color: #003c49;
}
.vivela-tracking-step--current .vivela-tracking-step__circle VivelaIcon {
  color: #ffffff;
}
.vivela-tracking-step--current .vivela-tracking-step__line {
  background-color: #003c49;
}

.card {
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
}
.card__header:empty {
  display: none;
}
.card.main-card {
  flex-direction: column;
}
.card.main-card .summary-wrapper {
  justify-content: flex-start;
  width: 100%;
}
.card.main-card .summary-wrapper .summary-content {
  width: fit-content;
}
.card.main-card .summary-wrapper .title {
  display: inline;
}
.card.main-card .detail {
  width: 100%;
}

@media (min-width: 768px) {
  .card {
    padding: 40px;
    margin-bottom: 32px;
  }
  .card.main-card {
    flex-direction: row;
  }
  .card.main-card .summary-wrapper {
    width: 40%;
    justify-content: center;
  }
  .card.main-card .summary-wrapper .title {
    display: block;
    font-size: 2.5rem; /* 40px */
    line-height: 1;
  }
  .card.main-card .summary-wrapper .title:first-child {
    margin-bottom: 8px;
  }
  .card.main-card .summary-wrapper .subtitle {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    margin: 15px 0;
  }
  .card.main-card .summary-wrapper .badge {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }
  .card.main-card .detail {
    width: 60%;
    padding-right: 60px;
    padding-left: 60px;
  }
}
.chip-box {
  display: flex;
  flex-wrap: wrap;
}
.chip-box .chip {
  margin: 10px 20px 10px 0;
  height: 40px;
  background: rgba(80, 80, 80, 0.15);
  border-radius: 50px;
  padding: 0 20px;
  cursor: pointer;
  color: #505050;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.chip-box .chip.active, .chip-box .chip.active:hover {
  background-color: rgba(0, 123, 128, 0.15);
  color: #007b80;
  font-weight: bold;
}

.paginator {
  display: flex;
  user-select: none;
}
.paginator__prev, .paginator__next, .paginator__page {
  width: 32px;
  height: 32px;
  margin: 2px;
  border: 1px solid #ecedee;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.paginator__prev, .paginator__next {
  color: #003c49;
}
.paginator__prev.disable, .paginator__next.disable {
  opacity: 70%;
  color: #ecedee;
  cursor: default;
}
.paginator__page.active {
  background: #007b80;
  color: white;
  border-color: #007b80;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
.modal-backdrop.modal-backdrop-showing {
  opacity: 0.8;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.modal {
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__content {
  width: 100%;
  max-width: 900px;
  z-index: 1000;
  pointer-events: auto;
}
.modal--sm .modal__content {
  max-width: 560px;
}
.modal__close {
  display: flex;
  justify-content: flex-end;
}
.modal__close .btn-icon {
  border: 1px solid #ecedee;
}
.modal__body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__body .mat-progress-bar-buffer {
  background-color: #c5c5c5;
}
.modal__body .mat-progress-bar .mat-progress-bar-fill::after {
  animation: running;
  width: 122px;
}
.modal__body .buttons {
  display: flex;
  justify-content: center;
}
.modal__body .btn {
  border: none;
}
.modal__body button {
  display: initial;
}

@media (min-width: 768px) {
  .cdk-overlay-dark-backdrop,
.modal-dark-backdrop {
    background-color: #515151;
  }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing,
.modal-backdrop.modal-backdrop-showing {
    opacity: 0.7;
  }
  .modal__body {
    margin: 45px 30px 50px 30px;
  }
  .modal__body .file-loading,
.modal__body .loading {
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .modal__body button {
    margin-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.file {
  text-align: left;
  padding: 10px 0px 10px 0px;
}

.photo {
  font-size: 15px;
  padding-bottom: 10px;
}

.upload-file {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 7px;
  width: 100%;
}

.upload-file img {
  max-width: 300px;
  width: 100%;
}

.imageIcon {
  justify-content: center;
  text-align: center;
  margin: 100px 0 100px 0;
  padding: 70px 10px;
}

.imageIcon p {
  color: #9fa4a9;
  font-size: 14px;
}

.imageIcon img {
  max-width: 50px;
  width: 100%;
}

/**
 * The following are web specific (used with any web app targets)
 */
/**
 * Element tag overrides
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
  font-size: initial;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .welcome-content, .card.main-card, .card.main-card .summary-wrapper, .card.main-card .detail {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .welcome-content {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .welcome-content, .card.main-card .summary-wrapper, .card.main-card .detail {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100, .rule__detail, .rule__header, .prequalifications-button-bottom .mat-stroked-button, .prequalifications-button-top .mat-stroked-button, .sidebar__logout button.mat-stroked-button, .sidebar .nav-list, .main-content {
  width: 100% !important;
}

.w-auto, .rule__header__content, .prequalifications-search .mat-form-field {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100, .welcome-content {
  height: 100% !important;
}

.h-auto, .sidebar__brand img, .login__intro__brand img {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.list__item {
  display: flex;
  align-items: center;
}
.list__item__decorator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list__item__decorator + .list__item__name {
  margin-left: 10px;
}
.list__item__value {
  margin-left: 20px;
}
.list__item__name + .list__item__value {
  margin-left: 5px;
}

@media (min-width: 768px) {
  .list__item {
    margin-top: 28px;
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
  .list__item:first-child {
    margin-top: 0;
  }
  .list__item__decorator {
    width: 25px;
    height: 25px;
  }
  .list__item__decorator .icon {
    font-size: 24px;
  }
}
.switch {
  display: flex;
  align-items: center;
}
.switch__btn[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
}
.switch__btn[type=checkbox] + label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: #dddddd;
  display: block;
  border-radius: 100px;
  position: relative;
}
.switch__btn[type=checkbox] + label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
  box-shadow: 0px 4px 4px rgba(34, 30, 120, 0.25);
}
.switch__btn[type=checkbox]:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.switch__btn[type=checkbox]:checked.primary + label {
  background-color: #007b80;
}
.switch__btn[type=checkbox]:checked.secondary + label {
  background-color: #003c49;
}
.switch__label {
  margin-left: 10px;
}

@font-face {
  font-family: VivelaIcons;
  src: url("./../assets/fonts/VivelaIcons.ttf");
  font-style: normal;
  font-weight: 400;
}
.icon {
  font-style: normal;
  font-family: VivelaIcons;
  display: block;
}

body {
  min-width: 360px;
}

a.return-normal {
  display: none;
}

button.return-mobile {
  display: block;
}

.mat-slider-min-value .mat-slider-thumb-label {
  background-color: #007b80 !important;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.layout {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.header__logo img {
  max-height: 70px;
}
.header__profile {
  overflow: hidden;
  border-radius: 100%;
}
.header__profile img {
  width: 50px;
  height: 50px;
}

.menu {
  display: flex;
}
.menu__item {
  text-decoration: none;
  padding: 10px 20px;
}
.menu__item:hover {
  background-color: #f5f5f5;
}
.menu__item.active {
  color: #007b80;
}

.page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;
  margin: 30px 0;
  color: #003c49;
  font-weight: 700;
}

.report-card {
  margin-bottom: 30px;
}

.profile-card .h3 {
  margin-bottom: 10px;
}
.profile-card__img {
  text-align: center;
}
.profile-card__img img {
  max-width: 130px;
}

.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.login__intro {
  flex: none;
}
.login__intro__brand {
  display: flex;
  justify-content: space-between;
}
.login__intro__img {
  display: none;
}
.login__form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.login__form .google-btn {
  width: 100%;
}

.recover .login__intro__content {
  display: none;
}

.sidebar {
  width: 100%;
  min-width: 360px;
  height: auto;
  min-height: auto;
}
.sidebar__brand {
  display: flex;
  justify-content: space-between;
}
.sidebar__brand button {
  display: block;
}
.sidebar .nav-list {
  z-index: 10;
}
.sidebar__menu {
  min-height: 400px;
}
.sidebar__menu__section {
  border-bottom: 1px solid #339599;
}
.sidebar__menu__section:last-child {
  border-bottom: 0;
}
.sidebar__menu__item {
  display: flex;
  align-items: center;
  user-select: none;
}
.sidebar__submenu {
  margin-left: 58px;
  border-left: 1px solid #b7ffa0;
}
.sidebar__submenu__item {
  user-select: none;
}
.sidebar__user {
  display: flex;
  border-top: 1px solid #339599;
  border-bottom: 1px solid #339599;
}
.sidebar__user__avatar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar__user__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.welcome-content {
  min-height: calc(100vh - 180px);
}

.prequalifications-search {
  display: inline-flex;
  width: 100%;
}
.prequalifications-search .mat-form-field {
  max-width: 195px;
}
.prequalifications-button-top .btn-icon {
  display: none;
}
.prequalifications-button-top .mat-stroked-button {
  display: block;
}

.prequalifications-button-bottom .btn-icon {
  display: none;
}
.prequalifications-button-bottom .mat-stroked-button {
  display: block;
}

.card .table-wrapper {
  display: grid;
  box-shadow: none;
  border: 1px solid #dadada;
}
.card .table-wrapper table {
  width: 100%;
  min-width: 600px;
}

.table-wrapper {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}
.table-wrapper .content,
.table-wrapper .table-content {
  overflow-x: scroll;
}
.table-wrapper .content .mat-row:hover,
.table-wrapper .table-content .mat-row:hover {
  background: whitesmoke;
}
.table-wrapper table,
.table-wrapper table.mat-table {
  width: 100%;
  min-width: 1300px;
  border-collapse: collapse;
}
.table-wrapper table tr,
.table-wrapper table.mat-table tr {
  border-bottom: 1px solid #c5c8cb;
}
.table-wrapper table tr:last-child,
.table-wrapper table.mat-table tr:last-child {
  border-bottom: 0px;
}
.table-wrapper mat-paginator {
  display: flex;
  justify-content: center;
  padding-right: 50px;
}
.table-wrapper .button-plus {
  position: absolute;
  right: -12px;
  bottom: -12px;
}

.container {
  padding: 0 !important;
  max-width: 100%;
}
.container .mat-flat-button {
  width: 100%;
}

.rule {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ecedee;
}
.rule:last-child {
  border-bottom: 0;
}
.rule__header {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.rule__header__code {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 0.5rem;
  line-height: 0.75rem;
}
.rule__header__content {
  display: flex;
  flex-direction: column;
}
.rule__header__content__description {
  display: flex;
  align-items: center;
  flex: 1;
}
.rule__header__content__periods {
  display: flex;
  align-items: center;
}
.simulation-card {
  border: 1px solid #dadada;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.simulation-card-primary {
  border: 2px solid #007b80;
}
.simulation-card__years {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 15px;
}
.simulation-card__amount {
  font-weight: bold;
  font-size: 24px;
  margin-top: 5px;
}
.simulation-card .simulator__list {
  width: 100%;
  margin-top: 15px;
}
.simulation-card .simulator__list__item {
  display: flex;
  padding: 20px 0;
  align-items: center;
}
.simulation-card .simulator__list__item__icon {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background: #ecedee;
  color: #9fa4a9;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-input-element:disabled,
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-select-value-text {
  color: #9fa4a9;
}

.mat-form-field-readonly .mat-form-field-outline {
  background: #ecedee;
  color: #003c49;
  border-radius: 7px;
}
.mat-form-field-readonly .mat-form-field-outline-start,
.mat-form-field-readonly .mat-form-field-outline-gap,
.mat-form-field-readonly .mat-form-field-outline-end {
  border-color: #c5c8cb !important;
}
.mat-form-field-readonly .mat-form-field-outline-gap {
  border-top: 0 !important;
}
.mat-form-field-readonly .mat-form-field-label {
  color: #003c49;
}

.user-form .credit-info,
.application-form .credit-info {
  border: 1px solid #9fa4a9;
  box-sizing: border-box;
  border-radius: 5px;
}
.user-form .credit-info__title,
.application-form .credit-info__title {
  font-weight: 700;
  font-size: 40px;
  color: #007b80;
}
.user-form .credit-info__subtitle,
.application-form .credit-info__subtitle {
  font-size: 20px;
  color: #072f37;
}
.user-form .credit-info ul,
.application-form .credit-info ul {
  list-style: none;
}
.user-form .credit-info ul li,
.application-form .credit-info ul li {
  color: #9fa4a9;
}
.user-form .credit-info ul li .text-bold, .user-form .credit-info ul li .google-btn p, .google-btn .user-form .credit-info ul li p, .user-form .credit-info ul li .button__text, .user-form .credit-info ul li .card__header, .user-form .credit-info ul li .modal__body .file-tittle-loading, .modal__body .user-form .credit-info ul li .file-tittle-loading,
.user-form .credit-info ul li .modal__body .file-tittle-success,
.modal__body .user-form .credit-info ul li .file-tittle-success,
.user-form .credit-info ul li .modal__body .file-tittle-error,
.modal__body .user-form .credit-info ul li .file-tittle-error,
.user-form .credit-info ul li .modal__body .file-tittle-delete,
.modal__body .user-form .credit-info ul li .file-tittle-delete,
.user-form .credit-info ul li .modal__body .title,
.modal__body .user-form .credit-info ul li .title, .user-form .credit-info ul li .login__form .title, .login__form .user-form .credit-info ul li .title, .user-form .credit-info ul li .login__form .subtitle, .login__form .user-form .credit-info ul li .subtitle, .user-form .credit-info ul li .sidebar__menu__item:hover .sidebar__menu__item__text, .sidebar__menu__item:hover .user-form .credit-info ul li .sidebar__menu__item__text, .user-form .credit-info ul li .sidebar__menu__item.active .sidebar__menu__item__text, .sidebar__menu__item.active .user-form .credit-info ul li .sidebar__menu__item__text, .user-form .credit-info ul li .sidebar__submenu__item:hover, .user-form .credit-info ul li .sidebar__submenu__item.active, .user-form .credit-info ul li .sidebar__user__avatar__text, .user-form .credit-info ul li .table-wrapper table th, .table-wrapper table .user-form .credit-info ul li th, .user-form .credit-info ul li .container .subtitle, .container .user-form .credit-info ul li .subtitle, .user-form .credit-info ul li .rule__header__content__periods,
.application-form .credit-info ul li .text-bold,
.application-form .credit-info ul li .google-btn p,
.google-btn .application-form .credit-info ul li p,
.application-form .credit-info ul li .button__text,
.application-form .credit-info ul li .card__header,
.application-form .credit-info ul li .modal__body .file-tittle-loading,
.modal__body .application-form .credit-info ul li .file-tittle-loading,
.application-form .credit-info ul li .modal__body .file-tittle-success,
.modal__body .application-form .credit-info ul li .file-tittle-success,
.application-form .credit-info ul li .modal__body .file-tittle-error,
.modal__body .application-form .credit-info ul li .file-tittle-error,
.application-form .credit-info ul li .modal__body .file-tittle-delete,
.modal__body .application-form .credit-info ul li .file-tittle-delete,
.application-form .credit-info ul li .modal__body .title,
.modal__body .application-form .credit-info ul li .title,
.application-form .credit-info ul li .login__form .title,
.login__form .application-form .credit-info ul li .title,
.application-form .credit-info ul li .login__form .subtitle,
.login__form .application-form .credit-info ul li .subtitle,
.application-form .credit-info ul li .sidebar__menu__item:hover .sidebar__menu__item__text,
.sidebar__menu__item:hover .application-form .credit-info ul li .sidebar__menu__item__text,
.application-form .credit-info ul li .sidebar__menu__item.active .sidebar__menu__item__text,
.sidebar__menu__item.active .application-form .credit-info ul li .sidebar__menu__item__text,
.application-form .credit-info ul li .sidebar__submenu__item:hover,
.application-form .credit-info ul li .sidebar__submenu__item.active,
.application-form .credit-info ul li .sidebar__user__avatar__text,
.application-form .credit-info ul li .table-wrapper table th,
.table-wrapper table .application-form .credit-info ul li th,
.application-form .credit-info ul li .container .subtitle,
.container .application-form .credit-info ul li .subtitle,
.application-form .credit-info ul li .rule__header__content__periods {
  color: #072f37;
}
.user-form .credit-info ul i.icon,
.application-form .credit-info ul i.icon {
  width: 31px;
  height: 31px;
  color: #00ad86;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-form .credit-info a,
.application-form .credit-info a {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  color: #072f37;
  outline: none;
  text-decoration: none;
}
.user-form .credit-info p,
.application-form .credit-info p {
  margin-bottom: 0;
}
.user-form .application-title,
.application-form .application-title {
  position: relative;
}
.user-form .print-float,
.application-form .print-float {
  position: initial;
  top: 0;
  right: 0;
  z-index: 100;
}

@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
.skeleton {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
}
.skeleton::before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.9), transparent);
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  animation: loading 0.6s infinite;
}
.skeleton p thead,
.skeleton h1 thead,
.skeleton h2 thead,
.skeleton [class*=heading-] thead,
.skeleton .list__item thead,
.skeleton table thead {
  position: relative;
}
.skeleton p span,
.skeleton h1 span,
.skeleton h2 span,
.skeleton [class*=heading-] span,
.skeleton .list__item span,
.skeleton table span {
  background: #eee;
  color: #eee;
  pointer-events: none;
  user-select: none;
}
.skeleton [class*=col-] {
  position: inherit;
}
.skeleton .form-field {
  height: 59px;
  margin-bottom: 25px;
  background: #eee;
  color: #eee;
  border-radius: 5px;
}
.skeleton .form-button {
  background: #eee;
  color: #eee;
  height: 36px;
}
.skeleton .form-chip {
  background: #eee;
  color: #eee;
  height: 54px;
  width: 120px;
  border-radius: 30px;
  margin-right: 20px;
}
.skeleton .form-chip:last-child {
  margin-right: 0;
}
.skeleton .list__item__decorator,
.skeleton .rule__code,
.skeleton .badge__circle,
.skeleton .badge__text,
.skeleton .mat-slide-toggle-bar,
.skeleton .mat-slide-toggle-thumb {
  color: #eee !important;
  background-color: #eee !important;
  pointer-events: none;
  user-select: none;
}

.mat-icon-button.btn-icon-add {
  border-radius: 5px;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary {
  color: #003c49;
  background: #ecedee;
}

.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: #fff;
  background: #007b80;
}

.card-activity-disable {
  background-color: #f3f7f9;
}
.card-activity-disable i.cursor-pointer, .card-activity-disable i.google-btn, .card-activity-disable i.sidebar__menu__item, .card-activity-disable i.sidebar__submenu__item, .card-activity-disable .table-wrapper .content i.mat-row, .table-wrapper .content .card-activity-disable i.mat-row,
.card-activity-disable .table-wrapper .table-content i.mat-row,
.table-wrapper .table-content .card-activity-disable i.mat-row {
  display: none;
}

.simulation-pdf {
  width: 595px;
  font-family: "Poppins", sans-serif;
}
.simulation-pdf .information {
  padding-bottom: 12px;
}
.simulation-pdf .headerPdf {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  background-color: #00b07b;
  padding: 14px 32px 14px 32px;
}
.simulation-pdf .header-presimulador {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  background-color: #00b07b;
  padding: 22px 32px 21px 32px;
}
.simulation-pdf .counselor {
  border-bottom-left-radius: 20px;
  font-size: 8px;
  color: #ffffff;
  background-color: #283261;
  padding: 5px 30px;
}
.simulation-pdf .counselor .row div {
  margin-right: 5px;
}
.simulation-pdf .header-title {
  font-size: 18px;
  padding-bottom: 4px;
  font-weight: 700;
}
.simulation-pdf .header-subtitle {
  font-size: 10px;
}
.simulation-pdf .header-subtitle-data {
  font-size: 10px;
  font-weight: 600;
}
.simulation-pdf .header-subtitle-data.client {
  font-weight: 700;
}
.simulation-pdf .logo {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  text-align: end;
  padding-bottom: 7px;
}
.simulation-pdf .subtitle-logo {
  font-size: 8px;
  font-weight: 600;
  line-height: 9.6px;
  text-align: right;
}
.simulation-pdf .summary {
  height: 660px;
  padding-top: 12px;
}
.simulation-pdf .content {
  padding: 0 30px;
}
.simulation-pdf .content-presimulador {
  padding: 0 30px;
}
.simulation-pdf .body-title {
  font-size: 10px;
  font-weight: 700;
  border-radius: 8px;
  background: #e5e5e5;
  padding: 8px 16px;
  color: #283261;
}
.simulation-pdf .body-subtitle {
  font-size: 10px;
  font-weight: 600;
  border-bottom: 1px solid #ecedee;
  padding-bottom: 5px;
  margin-bottom: 15px;
  color: #007b80;
}
.simulation-pdf .data {
  padding: 15px 15px 5px 15px;
  font-weight: 600;
}
.simulation-pdf .data-title {
  font-size: 8px;
  color: #696c80;
  line-height: 12px;
}
.simulation-pdf .data-items {
  padding-bottom: 12px;
  font-size: 10px;
  color: #141414;
  line-height: 15px;
}
.simulation-pdf .box {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-sizing: border-box;
  height: 75px;
}
.simulation-pdf .box-presimulador {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  box-sizing: border-box;
  height: 90px;
  padding-bottom: 20px;
  padding-top: 40px;
}
.simulation-pdf .payment {
  height: 652px;
  padding: 0px 30px;
}
.simulation-pdf table {
  border: 1px solid #c5c8cb;
  border-radius: 4px;
}
.simulation-pdf table thead {
  background-color: #e8e8e8;
  text-align: left;
}
.simulation-pdf table tr {
  height: 22px;
  border: 0.5px solid #e8e8e8;
}
.simulation-pdf table th {
  font-size: 6.5px;
  font-weight: 600;
  border: none;
  color: #003c49;
  padding: 0;
}
.simulation-pdf table td {
  color: #52575c;
  font-size: 6.5px;
  border: none;
  text-align: left;
}
.simulation-pdf table .align {
  text-align: center;
}
.simulation-pdf table tr.mat-footer-row {
  font-weight: bold;
}
.simulation-pdf table th.mat-header-cell:first-of-type,
.simulation-pdf table td.mat-cell:first-of-type {
  padding-left: 10px;
}
.simulation-pdf table th.mat-header-cell:last-of-type,
.simulation-pdf table td.mat-cell:last-of-type {
  padding-right: 10px;
  width: 65px;
}
.simulation-pdf .footerPdf {
  font-size: 6px;
  font-weight: 500;
  line-height: 11px;
  padding: 6px 32px 18.7px 32px;
  color: #788087;
}

.hidden-component {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.mat-tab-labels .mat-tab-label {
  opacity: 1;
  color: #c5c8cb;
}

.mat-tab-label.mat-tab-label-active {
  color: #007b80;
}

.drag-drop-files {
  border: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding: 180px 70px;
}

.drag-files {
  border: none;
  width: 100%;
  justify-content: center;
  display: block;
}

.document-list {
  padding: 4px 18px 8px;
  min-height: 350px;
}

.simulation-subtitle {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

@media (min-width: 768px) {
  body {
    background-color: #f6f8fb;
  }
  button.return-mobile {
    display: none;
  }
  a.return-normal {
    display: block;
  }
  .layout {
    flex-direction: row;
  }
  .main-content {
    padding: 50px 35px;
    background-color: inherit;
  }
  .row p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .login {
    flex-direction: row;
  }
  .login__intro {
    flex: 1;
    padding: 23px 60px;
  }
  .login__intro__brand {
    margin-bottom: 103px;
  }
  .login__intro__brand img {
    width: 140px;
  }
  .login__intro__info {
    margin: 0 auto;
    max-width: 352px;
  }
  .login__intro__info .title {
    margin-bottom: 13px;
    font-size: 2.5rem;
    line-height: 1;
  }
  .login__intro__info p {
    margin-right: 60px;
  }
  .login__intro__img {
    display: block;
  }
  .login__diagonal {
    width: 0;
    height: 0;
    border-top: calc(100vh - 82px) solid #007b80;
    border-right: 50px solid transparent;
  }
  .login__form {
    justify-content: center;
    padding: 0px 84px;
  }
  .login__form .title {
    margin-bottom: 85px;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .login__form .subtitle {
    margin-bottom: 15px;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .login__form .google-btn {
    margin-bottom: 65px;
    width: max-content;
  }
  .recover .login__intro {
    border-bottom-right-radius: 90px;
  }
  .recover .login__intro__content {
    display: block;
  }
  .sidebar {
    display: block;
    min-width: 260px;
    height: 100%;
    min-height: 100vh;
  }
  .sidebar__brand {
    display: block;
    padding-top: 23px;
    padding-bottom: 23px;
    text-align: center;
  }
  .sidebar__brand img {
    width: 140px;
    height: auto;
  }
  .sidebar__brand button {
    display: none;
  }
  .sidebar .nav-list {
    position: relative;
    z-index: auto;
    width: auto;
  }
  .sidebar__menu {
    margin: 30px 20px;
  }
  .sidebar__menu__section {
    margin-bottom: 10px;
    border-bottom: 0;
  }
  .sidebar__menu__section:last-child {
    margin-bottom: 0;
  }
  .sidebar__menu__item {
    padding: 14px 24px;
    border-radius: 10px;
  }
  .sidebar__submenu {
    margin-left: 37px;
  }
  .sidebar__submenu__item {
    padding: 14px 24px;
    border-radius: 10px;
  }
  .sidebar__user {
    padding: 20px 19px;
  }
  .sidebar__logout button.mat-stroked-button {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .prequalifications-search button.mat-flat-button {
    margin-left: 16px;
  }
  .prequalifications-button-top .btn-icon {
    display: block;
    position: absolute;
    z-index: 1;
    top: -30px;
    right: -15px;
  }
  .prequalifications-button-top .mat-stroked-button {
    display: none;
  }
  .prequalifications-button-bottom .btn-icon {
    display: block;
    position: absolute;
    z-index: 1;
    right: -15px;
    bottom: -35px;
  }
  .prequalifications-button-bottom .mat-stroked-button {
    display: none;
  }
  .table-wrapper mat-paginator {
    justify-content: end;
    padding-right: 40px;
  }
  .container {
    padding: 40px 60px !important;
    background: #fff;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    border-radius: 8px;
  }
  .container .subtitle {
    margin-bottom: 30px;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
  .container .mat-flat-button {
    width: auto;
  }
  .rule__header__code {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .rule__header__content {
    flex-direction: row;
    width: 100% !important;
  }
  .rule__header__content__description .name {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .rule__header__content__description .description {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .rule__detail {
    margin-left: 50px;
  }
  .user-form .credit-info__monthly-fee,
.application-form .credit-info__monthly-fee {
    margin-bottom: 0;
  }
  .user-form .print-float,
.application-form .print-float {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
@media print {
  button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
    display: none;
  }
  .sidebar {
    display: none;
  }
}
.grecaptcha-badge {
  display: none;
  visibility: hidden;
}

.prequalification__contact-info {
  display: flex;
  flex-direction: column;
}
.prequalification__contact-info__title {
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}
.prequalification__contact-info__message {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 10px 0;
}
.prequalification__contact-info__exit {
  display: flex;
  justify-content: center;
}

.application__pep-title {
  padding: 20px 40px;
  background: #00ad86;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}
.application__pep-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#activity-list-container .disabled {
  color: #788087 !important;
}
#activity-list-container .disabled .text-primary-200 {
  color: #788087 !important;
}

#references-container .disabled {
  color: #788087 !important;
}
#references-container .disabled .text-primary-200 {
  color: #788087 !important;
}

.prequalification-import .summary {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin-top: 55px;
  padding: 10px;
  height: 427px;
  overflow-y: scroll;
}