.vivela-contact-bar {
  padding: 0 20;
  width: 100%;
  height: 75;
  border-top-left-radius: 15;
  border-top-right-radius: 15;
  background-color: $primary-light;
  &__avatar {
    margin-right: 10;
  }
  &__name {
    font-size: 16;
    color: $on-surface-high-emphasis;
    @extend .text-medium;
  }
  &__occupation {
    font-size: 14;
    color: $on-surface-high-emphasis;
    @extend .text-light;
  }
}
