VivelaListItem {
  padding: 16 8;

  VivelaIcon {
    margin-right: 8;
  }

  Label {
    font-size: 14;
    @extend .text-medium;
  }
}
