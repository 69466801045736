.btn {
  font-weight: bold;
  font-size: size($btn-font-size);
  padding: size(0 24);
  text-align: center;
  vertical-align: middle;
  height: size($btn-height);
  border-radius: size($btn-border-radius);
  border: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &-icon {
    border-radius: 100%;
    width: size(50);
    height: size(50);
    min-width: size(0);
    text-align: center;
    padding: 0;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    background-color: $value;
    color: color-contrast($value);
    &:hover,
    &:active,
    &:focus {
      background-color: darken($value, 7.5%);
      color: color-contrast($value);
    }
    &.disable {
      background-color: rgba($value, 0.6);
    }
  }
}

.btn-link {
  background-color: transparent;
  color: $primary;
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  width: max-content;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 2.41919px rgba(0, 0, 0, 0.084),
    0px 2.41919px 2.41919px rgba(0, 0, 0, 0.168);
  @extend .p-15;
  @extend .radius-5;
  @extend .text-center;
  @extend .cursor-pointer;
  @extend .bg-white;

  .google-icon-wrapper {
    @extend .my-auto;
    @extend .mr-10;
    width: 25px;
    height: 25px;
  }

  p {
    @extend .py-5;
    @extend .my-auto;
    @extend .text-sm;
    @extend .text-regular;
    @extend .text-bold;
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $primary;
}

button.mat-flat-button,
button.mat-stroked-button,
a.mat-flat-button,
a.mat-stroked-button {
  @extend .py-8;
  @extend .px-32;
  @extend .mr-0;
  @extend .mb-16;
  @extend .text-uppercase;
  @extend .tracking-widest;
  width: 100%;
  white-space: normal;
}

.d-inline-flex {
  button.mat-flat-button,
  button.mat-stroked-button,
  a.mat-flat-button,
  a.mat-stroked-button {
    @extend .mr-16;
    @extend .mb-0;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (min-width: $grid-m-min) {
  button.mat-flat-button,
  button.mat-stroked-button,
  a.mat-flat-button,
  a.mat-stroked-button {
    margin-right: 16px;
    margin-bottom: 25px;
    width: max-content;

    &:last-child {
      margin-right: 0;
    }
  }
}
