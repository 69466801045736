.select {
  &__btn {
    background-color: $select-btn-background;
    padding: size($select-btn-padding);
    color: $select-btn-color;
    border-width: size(1);
    border-color: $select-btn-border-color;
    border-radius: size($select-btn-border-raduis);
    font-size: size($select-font-size);
    vertical-align: center;
    &__icon {
      width: size($select-btn-icon-size);
      height: size($select-btn-icon-size);
      border-radius: 100%;
      background: $select-btn-icon-background;
      text-align: center;
    }
    &:focus-visible {
      outline-color: $select-btn-border-color;
    }
    &.invalid:not(:focus-visible) {
      border: solid 2px $error;
    }
  }
  &__options {
    margin-top: size($select-space-btn-options);
    &--border {
      background-color: $select-options-background;
      border-radius: size($select-options-border-radius);
      border-width: 1;
      border-color: $select-options-border-color;
    }
    &.collapsed {
      visibility: collapsed;
    }
    .option {
      color: $select-option-color;
      height: size($select-option-height);
      padding: size($select-option-padding);
      border: none;
      &.active {
        background: $select-option-active-background-color;
      }
    }
    .separator {
      border-bottom-width: size(1);
      border-bottom-color: $select-options-separator-color;
    }
  }
}
