.button {
  height: 50;
  width: 220;
  border-radius: 25;
  &__text {
    color: $on-background-high-emphasis;
    font-size: 14;
    text-transform: uppercase;
    @extend .text-bold;
  }
}

.button--primary {
  background-color: $primary;
  .button__text {
    color: $on-primary-high-emphasis;
  }
}

.button--secondary {
  background-color: $secondary;
  .button__text {
    color: $on-secondary-high-emphasis;
  }
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: blink;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
