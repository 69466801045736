.card {
  @extend .py-32;
  @extend .px-16;
  @extend .mb-20;
  @extend .radius-8;
  @extend .bg-white;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);

  &__header {
    @extend .mb-30;
    @extend .text-xl;
    @extend .text-bold;

    &:empty {
      display: none;
    }
  }

  &.main-card {
    @extend .d-flex;
    flex-direction: column;
    @extend .text-regular;

    .summary-wrapper {
      @extend .d-flex;
      @extend .align-items-center;
      justify-content: flex-start;
      width: 100%;

      .summary-content {
        width: fit-content;
      }

      .title {
        display: inline;
        @extend .text-xl;
        @extend .text-left;

        &:first-child {
          @extend .mb-0;
        }
      }

      .subtitle {
        @extend .mt-8;
        @extend .mb-25;
      }

      .subtitle,
      .badge {
        @extend .text-base;
      }
    }

    .detail {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .px-0;
      width: 100%;
    }
  }
}

@media (min-width: $grid-m-min) {
  .card {
    padding: 40px;
    margin-bottom: 32px;

    &.main-card {
      flex-direction: row;

      .summary-wrapper {
        width: 40%;
        justify-content: center;

        .title {
          display: block;
          font-size: 2.5rem; /* 40px */
          line-height: 1;

          &:first-child {
            margin-bottom: 8px;
          }
        }

        .subtitle {
          font-size: 1.25rem; /* 20px */
          line-height: 1.75rem; /* 28px */
          margin: 15px 0;
        }

        .badge {
          font-size: 1.125rem; /* 18px */
          line-height: 1.75rem; /* 28px */
        }
      }

      .detail {
        width: 60%;
        padding-right: 60px;
        padding-left: 60px;
      }
    }
  }
}
