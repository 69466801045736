.vivela-switch {
  &__track {
    border-radius: 30;
    width: 100%;
  }
  &__track--ios {
    top: 0;
    left: 0;

    height: 30;
    background: $primary-light;
  }
  &__track--android {
    top: 6;
    left: 0;

    height: 18;
    background: $primary-light;
  }
  &__track--active {
    background: $secondary;
  }
  &__thumb {
    width: 100%;
    height: 100%;
    border-radius: 30;
    background: $on-secondary-high-emphasis;
    android-elevation: 5;
  }
}
