/**
 * Element tag overrides
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: $body-font-family;
  font-size: $body-font-size;
}
