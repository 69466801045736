.imageIcon {
  justify-content: center;
  text-align: center;
  margin: 100px 0 100px 0;
  padding: 70px 10px;
}
.imageIcon p {
  color: #9fa4a9;
  font-size: 14px;
}
.imageIcon img {
  max-width: 50px;
  width: 100%;
}
