.prequalification {
  &__contact-info {
    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      padding: 5px;
      font-weight: bold;
      font-size: 16px;
    }
    &__message {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin: 10px 0;
    }
    &__exit {
      display: flex;
      justify-content: center;
    }
  }
}
